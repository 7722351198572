import { USER_PANEL_VIEW } from '../constants';
import useHeaderPropsQuery from './queries/useHeaderPropsQuery';

const useAccountPanelType = () => {
  const {
    data: { isKnownUser },
  } = useHeaderPropsQuery();

  return isKnownUser ? USER_PANEL_VIEW.ACCOUNT : USER_PANEL_VIEW.SIGNED_OUT;
};

export default useAccountPanelType;
