import { useTranslations } from '@unisporkal/localization';
import { useCollectionsOverviewLinks } from '../useOverviewLinks';

export const usePartnerCollections = () => {
  const trackingId = 'Collections_Expert_Curations';
  const t = useTranslations();
  const collectionsOverviewLinks = useCollectionsOverviewLinks();

  return {
    trackingId,
    overviewLink: collectionsOverviewLinks.partnerCollections,
    overviewText: {
      title: t('partner_collections'),
      body: t('explore_collections_of_diverse_images_and_videos'),
    },
  };
};

export default usePartnerCollections;
