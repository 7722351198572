import React from 'react';
import styles from './NavBar.module.scss';

export enum NavBarVariantEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

interface NavBarProps {
  variant?: NavBarVariantEnum;
  children: React.ReactNode;
}

const NavBar = ({
  variant = NavBarVariantEnum.PRIMARY,
  children,
}: NavBarProps) => (
  <nav
    data-testid="nav-bar"
    className={`${styles.navBar} ${styles[variant]}`}
  >
    {children}
  </nav>
);

export default NavBar;
