import { useMemo } from 'react';
import {
  useSiteMap,
  Ftlps,
  useSite,
  TSiteMap,
  AssetCategoryType,
} from '@unisporkal/sites';
import { useTranslations, useLocale } from '@unisporkal/localization';
import { NavLinkItem } from '../../../types/link';
import useHeaderPropsQuery from '../queries/useHeaderPropsQuery';

interface NavLinkData extends NavLinkItem {
  id: string;
}

export const getAiGeneratorHref = (
  hasActiveGenerationAgreement: boolean,
  siteMap: TSiteMap,
  showAiGenPreShotMerchandising: boolean
): string => {
  if (hasActiveGenerationAgreement) {
    return siteMap.aiGenerator();
  }
  if (showAiGenPreShotMerchandising) {
    return siteMap.aiGenerationPricing();
  }
  return siteMap.aiGenerationLanding();
};

// eslint-disable-next-line import/prefer-default-export
export const useNavLinks = () => {
  const t = useTranslations();
  const siteMap = useSiteMap();
  const locale = useLocale();

  const {
    data: {
      cacheSafeCountryCode,
      hasActiveGenerationAgreement,
      showAiGenPreShotMerchandising,
    },
  } = useHeaderPropsQuery();

  const site = useSite();

  const ftlps = new Ftlps(site.nickname, site.tld);

  // Collections is being updated to eventually deprecate /solutions/collections which is
  // collectionsUri(). The new landing page is /collections which is collectionsLanding().
  // In the future as we expand to all languages we will only use collectionsLanding().
  const collectionsUrl =
    locale === 'en-us' || locale === 'en-gb'
      ? siteMap.collectionsLanding()
      : siteMap.collectionsUri();

  const hrefAiGenerator = getAiGeneratorHref(
    hasActiveGenerationAgreement,
    siteMap,
    showAiGenPreShotMerchandising
  );

  const curatedContentUrl = siteMap?.curatedContent?.(AssetCategoryType);

  return useMemo<{ [key: string]: NavLinkData }>(() => {
    const links: NavLinkData[] = [
      {
        id: 'aiGenerator',
        href: hrefAiGenerator,
        gaName: 'ai_generator',
        uaEventLabel: 'nav_AI_Generator',
        name: t('ai_generator_title_casing'),
      },
      {
        id: 'aiGeneratorPricing',
        href: siteMap.aiLanding(),
        gaName: 'overview_and_pricing',
        uaEventLabel: 'nav_AI_Generator_Pricing',
        name: t('overview_and_pricing'),
      },
      {
        id: 'aiGenerateNewImages',
        href: siteMap.aiGeneration(),
        gaName: 'generate_new_images',
        uaEventLabel: 'nav_AI_Generate_New_Images',
        name: t('ai_generate_new_images'),
      },
      {
        id: 'aiGeneratorGenerate',
        href: siteMap.aiGenerator(),
        gaName: 'start_generating_ai',
        uaEventLabel: 'nav_AI_Generator',
        name: t('ai_generate_new_images'),
      },
      {
        id: 'aiGeneratorModify',
        href: siteMap.aiModification(),
        gaName: 'modify_creative_images',
        uaEventLabel: 'nav_AI_Generator_Modify',
        name: t('modify_creative_images'),
      },
      {
        id: 'aiGeneratorCustom',
        href: siteMap.aiFineTune(),
        gaName: 'custom_fine-tuning',
        uaEventLabel: 'nav_AI_Generator_Custom',
        name: t('custom_fine_tuning'),
      },
      {
        id: 'aiPlansAndPricingFaq',
        href: siteMap.aiPlansAndPricingFaq(),
        gaName: 'ai_plans_and_pricing_faq',
        uaEventLabel: 'nav_AI_Generator_Plans_and_Pricing_Faq',
        name: t('faqs'),
      },
      {
        id: 'aiGenerateNewImagesFaq',
        href: siteMap.aiGenerateImagesFaq(),
        gaName: 'ai_generator_faq',
        uaEventLabel: 'nav_AI_Generator_New_Images_Faq',
        name: t('faqs'),
      },
      {
        id: 'aiModifyCreativeImagesFaq',
        href: siteMap.aiModifyCreativeImagesFaq(),
        gaName: 'ai_generator_faq',
        uaEventLabel: 'nav_AI_Generator_Modify_Creative_Images_Faq',
        name: t('faqs'),
      },
      {
        id: 'aiGeneratorUserGuide',
        href: siteMap.aiUserGuide(),
        gaName: 'ai_generator_user_guide',
        uaEventLabel: 'nav_AI_Generator_User_Guide',
        name: t('ai_user_guide'),
      },
      {
        id: 'aiNewFeatures',
        href: siteMap.aiNewFeatures(),
        gaName: 'ai_generator_features',
        uaEventLabel: 'nav_AI_Generator_Features',
        name: t('whats_new'),
      },
      {
        id: 'aiGenerationLookbook',
        href: siteMap.aiGenerationLookbook(),
        gaName: 'ai_generator_lookbook',
        uaEventLabel: 'nav_AI_Generator_Lookbook',
        name: t('whats_new'),
      },
      {
        id: 'bbcMotionGallery',
        href: siteMap.cleanPath(siteMap.map.bbcMotionGallery),
        gaName: 'bbc_motion_gallery',
        uaEventLabel: 'nav_BBC_Motion_Gallery',
        name: t('bbc_motion_gallery'),
      },
      {
        id: 'cart',
        href: siteMap.cleanPath(siteMap.cart()),
        gaName: 'cart',
        uaEventLabel: 'nav_Cart',
        name: t('cart_caps'),
      },
      {
        id: 'collections',
        href: collectionsUrl,
        gaName: 'collections',
        uaEventLabel: 'nav_Collections',
        name: t('collections'),
      },
      {
        id: 'collectionsExpertCurations',
        href: ftlps.expertCurationCollections(),
        gaName: 'collections',
        uaEventLabel: 'nav_Collections_Expert_Curations',
        name: t('expert_curations'),
      },
      {
        id: 'collectionsMediaAndSportsCoverage',
        href: ftlps.mediaAndSportsCollections(),
        gaName: 'collections',
        uaEventLabel: 'nav_Collections_Media_And_Sports_Coverage',
        name: t('media_and_sports_coverage'),
      },
      {
        id: 'collectionsPartnerCollections',
        href: ftlps.partnerCollections(),
        gaName: 'collections',
        uaEventLabel: 'nav_Collections_Partner_Collections',
        name: t('partner_collections'),
      },
      {
        id: 'creative',
        href: siteMap.map.creative,
        gaName: 'creative',
        uaEventLabel: 'nav_Creative',
        name: t('creative'),
      },
      {
        id: 'creativeImages',
        gaName: 'images',
        uaEventLabel: 'nav_Creative_Images',
        name: t('images'),
        href: ftlps.photos(),
      },
      {
        id: 'creativeIllustrations',
        gaName: 'illustrations',
        uaEventLabel: 'nav_Creative_Illustrations',
        name: t('illustrations'),
        href: ftlps.illustrations(),
      },
      {
        id: 'creativeMusic',
        gaName: 'music',
        uaEventLabel: 'nav_Creative_Music',
        name: t('music'),
        href: siteMap.music(),
      },
      {
        id: 'creativeVideos',
        gaName: 'videos',
        uaEventLabel: 'nav_Creative_Videos',
        name: t('videos'),
        href: ftlps.videos(),
      },
      {
        id: 'creativeVectors',
        gaName: 'vectors',
        uaEventLabel: 'nav_Creative_Vectors',
        name: t('vectors'),
        href: ftlps.vectors(),
      },
      {
        id: 'creativeVideo',
        href: siteMap.map.creativeVideo,
        gaName: 'creative_video',
        uaEventLabel: 'nav_creativeVideo',
        name: t('creative'),
      },
      {
        id: 'curatedContent',
        href: curatedContentUrl,
        gaName: 'curated_content',
        uaEventLabel: 'nav_Curated_Content',
        name: t('curated_by_getty_images'),
      },
      {
        id: 'editorial',
        href: siteMap.map.editorialImages,
        gaName: 'editorial',
        uaEventLabel: 'nav_Editorial',
        name: t('editorial'),
      },
      {
        id: 'editorialArchive',
        gaName: 'archive',
        uaEventLabel: 'nav_Editorial_Archive',
        name: t('archive'),
        href: siteMap.cleanPath(siteMap.map.editorialImagesArchival),
      },
      {
        id: 'editorialEntertainment',
        gaName: 'entertainment',
        uaEventLabel: 'nav_Editorial_Entertainment',
        name: t('entertainment'),
        href: siteMap.cleanPath(siteMap.map.editorialImagesEntertainment),
      },
      {
        id: 'editorialFashion',
        gaName: 'fashion',
        uaEventLabel: 'nav_Editorial_Fashion',
        name: t('fashion'),
        href: siteMap.cleanPath(siteMap.map.editorialImagesFashion),
      },
      {
        id: 'editorialImages',
        gaName: 'images',
        uaEventLabel: 'nav_Editorial_Images',
        name: t('images'),
        href: siteMap.cleanPath(siteMap.map.editorialImages),
      },
      {
        id: 'editorialNews',
        gaName: 'news',
        uaEventLabel: 'nav_Editorial_News',
        name: t('news'),
        href: siteMap.cleanPath(siteMap.map.editorialImagesNews),
      },
      {
        id: 'editorialSports',
        gaName: 'sports',
        uaEventLabel: 'nav_Editorial_Sports',
        name: t('sports'),
        href: siteMap.cleanPath(siteMap.map.editorialImagesSport),
      },
      {
        id: 'editorialUserGenerated',
        gaName: 'user-generated',
        uaEventLabel: 'nav_Editorial_User_Generated',
        name: t('user_generated_with_hyphen'),
        href: siteMap.cleanPath(siteMap.map.editorialFootageUserGenerated),
      },
      {
        id: 'editorialVideo',
        href: siteMap.map.editorialVideo,
        gaName: 'editorial_video',
        uaEventLabel: 'nav_editorialVideo',
        name: t('editorial'),
      },
      {
        id: 'editorialVideos',
        gaName: 'videos',
        uaEventLabel: 'nav_Editorial_Videos',
        name: t('videos'),
        href: siteMap.cleanPath(siteMap.map.editorialVideo),
      },
      {
        id: 'enterprise',
        href: siteMap.cleanPath(siteMap.enterprise()),
        gaName: 'enterprise',
        uaEventLabel: 'nav_enterprise',
        name: t('enterprise_header_nav'),
      },
      {
        id: 'enterpriseCustomContent',
        gaName: 'enterprise_custom_content',
        uaEventLabel: 'nav_Enterprise_Custom_Content',
        name: t('custom_content'),
        href: siteMap.customContent(),
      },
      {
        id: 'enterpriseMediaManager',
        gaName: 'enterprise_media_manager',
        uaEventLabel: 'nav_Enterprise_Media_Manager',
        name: t('media_manager'),
        href: siteMap.mediaManager(),
      },
      {
        id: 'enterprisePremiumAccess',
        gaName: 'enterprise_premium_access',
        uaEventLabel: 'nav_Enterprise_Premium_Access',
        name: t('premium_access'),
        href: siteMap.premiumAccess(),
      },
      {
        id: 'music',
        href: siteMap.music(),
        gaName: 'music',
        uaEventLabel: 'nav_Music',
        name: t('music'),
      },
      {
        id: 'nbcNewsArchive',
        href: siteMap.cleanPath(siteMap.map.nbcNewsArchive),
        gaName: 'bbc_news_archives',
        uaEventLabel: 'nav_NBC_News_Archive',
        name: t('nbc_news_archives'),
      },
      {
        id: 'pricing',
        href: siteMap.cleanPath(siteMap.plansAndPricing()),
        gaName: 'pricing',
        uaEventLabel: 'nav_Pricing',
        name: t('pricing'),
      },
      {
        id: 'projectShowUs',
        href: siteMap.cleanPath(siteMap.map.showUsDoveCampaign),
        gaName: 'project_show_us',
        uaEventLabel: 'nav_Project_ShowUs',
        name: t('project_showus'),
      },
      {
        id: 'topCreativeImageSearches',
        href: siteMap.mostPopularSearches(
          AssetCategoryType.IMAGES,
          cacheSafeCountryCode
        ),
        gaName: 'top_creative_image_searches',
        uaEventLabel: 'nav_Top_Creative_Image_Searches',
        name: t('top_image_searches_lower'),
      },
      {
        id: 'topCreativeVideoSearches',
        href: siteMap.mostPopularSearches(
          AssetCategoryType.VIDEOS,
          cacheSafeCountryCode
        ),
        gaName: 'top_creative_video_searches',
        uaEventLabel: 'nav_Top_Creative_Video_Searches',
        name: t('top_video_searches_lower'),
      },
      {
        id: 'unsplashForBrands',
        href: siteMap.advertiseOnUnsplash(),
        gaName: 'enterprise_unsplash_for_brands',
        uaEventLabel: 'nav_Enterprise_Unsplash_For_Brands',
        name: 'unsplash',
        target: '_blank',
      },
      {
        id: 'video',
        href: siteMap.map.creativeVideo,
        gaName: 'video',
        uaEventLabel: 'nav_Video',
        name: t('video'),
      },
      {
        id: 'visualGps',
        href: siteMap.visualGps(),
        gaName: 'visual_gps_insights',
        uaEventLabel: 'nav_visualGps',
        name: t('trends_and_insights'),
        target: '_blank',
      },
    ];

    const linksById: { [key: string]: NavLinkData } = Object.fromEntries(
      links.map((link) => [link.id, link] as [string, NavLinkData])
    );

    return linksById;
  }, []);
};
