import React, { useState, useEffect } from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { List, ListItem } from '@unisporkal/alliance-ui-list';
import { Link } from '@unisporkal/alliance-ui-link';
import { ga4Track, loginInteraction, navigationEvent } from '@unisporkal/ga4';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '@unisporkal/alliance-ui-button';
import {
  trackSelectLogin,
  trackNavigation,
} from '../../../../tracking/navigationMenuTracking';
import styles from './AccountPanel.module.scss';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

interface AccountPanelProps {
  openBoardsPanel: () => void;
}

const AccountPanel = ({ openBoardsPanel }: AccountPanelProps) => {
  const siteMap = useSiteMap();
  const t = useTranslations();
  const signInPath = siteMap.map.signIn;
  const registerPath = siteMap.map.register;

  const accountPath = siteMap.account();
  const downloadHistoryPath = siteMap.accountDownload();
  const visualGpsDashboardPath = siteMap.visualGpsPremiumDashboard();
  const purchasehistoryPath = siteMap.map.purchaseHistory;
  const aiGenHistoryPath = siteMap.map.aiGenerationHistory;
  const invoicesPath = siteMap.map.invoices;

  const {
    data: {
      displayName,
      isKnownUser,
      premiumDashBoardEnabled,
      hasActiveGenerationAgreement,
    },
  } = useHeaderPropsQuery();
  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {
    const returnPath = window.location.pathname + window.location.search;
    setCurrentLocation(returnPath);
  }, []);

  const showAIGenHistoryLink = () => hasActiveGenerationAgreement;

  const signInTracking = () => {
    trackSelectLogin('account_panel');
    trackNavigation({
      name: 'sign_in',
      path: signInPath,
      location: 'header_side_panel_right',
    });
  };

  const trackMenuClick = (item) => {
    const ga4Event = navigationEvent({
      link_text: item.ga4Name,
      link_url: item.href,
      ui_element_location: 'account_panel',
      navigation_selection: item.ga4Name,
    });
    ga4Track(ga4Event);
  };

  const trackSignOut = () => {
    const ga4Event = loginInteraction({
      event_name: 'logout',
      ui_element_location: 'account_panel',
    });
    ga4Track(ga4Event);
    trackMenuClick({
      ga4Name: 'Sign out',
      href: siteMap.signOutPath(encodeURIComponent(currentLocation)),
    });
  };

  const rightPanelTracking = (gaName, nav, href) => {
    const uiElementLocation = 'header_side_panel_right';
    const ga4Event = navigationEvent({
      navigation_selection: nav,
      link_text: gaName,
      link_url: href,
      ui_element_location: uiElementLocation,
    });
    ga4Track(ga4Event);
  };

  const anonymousPanel = () => (
    <List
      className={styles.list}
      data-testid="panel-for-anonymous-user"
    >
      <ListItem className={styles.listItem}>
        <Link
          href={signInPath}
          onClick={signInTracking}
          className={styles.link}
          data-testid="right-panel-signIn"
          data-nav="nav_Account_SignIn"
        >
          {t('sign_in')}
        </Link>
      </ListItem>
      <ListItem className={styles.listItem}>
        <Link
          href={registerPath}
          onClick={() =>
            trackNavigation({
              name: 'register',
              path: registerPath,
              location: 'header_side_panel_right',
            })
          }
          className={styles.link}
          data-testid="right-panel-register"
          data-nav="nav_Account_Register"
        >
          {t('register')}
        </Link>
      </ListItem>
      <ListItem className={styles.listItem}>
        <Button
          data-testid="right-panel-boards"
          variant={ButtonVariantEnum.SECONDARY}
          size={ButtonSizeEnum.LARGE}
          title={t('boards')}
          className={styles.boardsLink}
          onClick={openBoardsPanel}
        >
          {t('boards')}
        </Button>
      </ListItem>
    </List>
  );

  const loggedInPanel = () => (
    <div
      data-testid="LoggedInPanel"
      className={styles.accountInfo}
    >
      <div className={styles.headingGroup}>
        <div className={styles.header}>{t('your_account')}</div>
        <div className={styles.username}>{displayName}</div>
      </div>
      <div id="customer_notifications_user_panel_portal" />
      <div className={styles.linksGroup}>
        <a
          className={styles.link}
          href={accountPath}
          onClick={() =>
            rightPanelTracking('overview', 'nav_Account_Overview', accountPath)
          }
        >
          {t('overview')}
        </a>
        <a
          className={styles.link}
          href={downloadHistoryPath}
          onClick={() =>
            rightPanelTracking(
              'download_history',
              'nav_Account_Download_History',
              downloadHistoryPath
            )
          }
        >
          {t('download_history')}
        </a>
        {premiumDashBoardEnabled ? (
          <a
            className={styles.link}
            href={visualGpsDashboardPath}
            onClick={() =>
              rightPanelTracking(
                'visual_analysis',
                'nav_Account_premium_dashboard',
                visualGpsDashboardPath
              )
            }
          >
            <span>{t('visualgps_insights_DA_title')}</span>
            <span className={styles.betaBadge}>{t('beta_upcase')}</span>
          </a>
        ) : null}
        <a
          className={styles.link}
          href={purchasehistoryPath}
          onClick={() =>
            rightPanelTracking(
              'purchase_history',
              'nav_Account_Purchase_History',
              purchasehistoryPath
            )
          }
        >
          {t('purchase_history')}
        </a>
        {showAIGenHistoryLink() ? (
          <a
            className={styles.link}
            href={aiGenHistoryPath}
            data-testid="generation-history"
            onClick={() =>
              rightPanelTracking(
                'generation_history',
                'nav_Account_Generation_History',
                aiGenHistoryPath
              )
            }
          >
            {t('generation_history')}
          </a>
        ) : null}
        <a
          className={styles.link}
          href={invoicesPath}
          onClick={() =>
            rightPanelTracking('invoices', 'nav_Account_Invoices', invoicesPath)
          }
        >
          {t('invoices')}
        </a>
        <Button
          className={styles.boardsLink}
          onClick={openBoardsPanel}
        >
          {t('boards')}
        </Button>
        <Link
          buttonStyleProps={{
            variant: ButtonVariantEnum.HOLLOW,
            size: ButtonSizeEnum.MEDIUM,
            fullWidth: true,
          }}
          data-testid="SignOut"
          className={styles.signOut}
          href={siteMap.signOutPath(encodeURIComponent(currentLocation))}
          onClick={() => trackSignOut()}
        >
          {t('sign_out_caps')}
        </Link>
      </div>
    </div>
  );

  const renderAccountType = () => {
    if (isKnownUser) {
      return loggedInPanel();
    }
    return anonymousPanel();
  };
  return renderAccountType();
};

export default AccountPanel;
