import React, { useState } from 'react';
import { Link } from '@unisporkal/alliance-ui-link';
import { List, ListItem } from '@unisporkal/alliance-ui-list';
import { useSiteMap } from '@unisporkal/sites';
import Account from '../Account/Account';
import {
  PANELTYPES,
  PANELTYPES_RIGHT,
  PANEL_VIEW,
} from '../../../../constants';
import { closePanelTrackingEvent } from '../../../../tracking/navigationMenuTracking';
import VisualGpsLogo from '../icons/VisualGpsLogo.svg?icon';
import VisualGpsMobileLogo from '../../../icons/visualGPSMobileLogo.svg?icon';
import RightPanel from '../RightPanel/RightPanel';
import styles from './VisualGpsHeader.module.scss';

const VisualGpsHeader = () => {
  const siteMap = useSiteMap();
  const href = siteMap.visualGpsLanding();
  const [rightPanelType, setRightPanelType] =
    useState<Nullable<PANELTYPES_RIGHT>>(null);

  const closeRightPanel = (panelType: PANEL_VIEW) => {
    setRightPanelType(null);
    // Note: open panel tracking is handled in the panel components
    closePanelTrackingEvent('right', panelType);
  };

  return (
    <header
      data-testid="visual-gps-header"
      className={styles.container}
      id="site-top-header-wrapper"
    >
      <Link
        data-testid="nav-link-visual_gps_insights"
        href={href}
        className={styles.link}
      >
        <VisualGpsLogo
          role="img"
          className={styles.desktop}
          aria-label="Getty Visual GPS Logo"
          data-testid="visualGpsLogo"
        />
        <VisualGpsMobileLogo
          role="img"
          className={styles.mobile}
          aria-label="Getty Visual GPS Mobile Logo"
          data-testid="visualGpsMobileLogo"
        />
      </Link>
      <span className={styles.signInButton}>
        <RightPanel
          className={styles.rightPanel}
          panelSlideMs={300}
          panelComponentType={rightPanelType}
          onPanelNavigate={setRightPanelType}
          onClose={closeRightPanel}
        />
        <nav className={styles.accountContainer}>
          <List className={styles.navList}>
            <ListItem
              className={`${styles.navListItem} ${styles.accountNavItem}`}
              data-testid="SignIn"
            >
              <Account
                openAccountPanel={() => setRightPanelType(PANELTYPES.USER)}
                visualGpsHeader
              />
            </ListItem>
          </List>
        </nav>
      </span>
    </header>
  );
};

export default VisualGpsHeader;
