import { useTranslations } from '@unisporkal/localization';
import { AssetCategoryType } from '@unisporkal/sites';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import { useCreativeOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';
import useCuratedContent from './useCuratedContent';

export const useCreativeVideos = () => {
  const trackingId = 'Creative_Videos';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const curatedContent = useCuratedContent();
  const creativePopularCategoryLinks =
    popularCategories.creativePopularCategoryLinks();
  const curatedContentLinks = curatedContent.getSeasonalContentLinks(
    AssetCategoryType.VIDEOS
  );

  const {
    data: { creativeTrendingSearchLinksVideo },
  } = useHeaderPropsQuery();

  const creativeOverviewLinks = useCreativeOverviewLinks();

  return {
    trackingId,
    overviewLink: creativeOverviewLinks.creativeVideos,
    topSearchesLink: creativeOverviewLinks.topCreativeVideoSearches,
    overviewText: {
      title: t('creative_videos'),
      body: t('check_out_millions_of_royalty_free_videos'),
    },
    curatedPills: {
      title: t('curated_by_getty_images'),
      items: curatedContentLinks,
    },
    trendingPills: {
      title: t('trending_video_searches'),
      items: creativeTrendingSearchLinksVideo,
    },
    popularPills: {
      title: t('popular_video_categories'),
      items: creativePopularCategoryLinks.videos,
    },
  };
};

export default useCreativeVideos;
