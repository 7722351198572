import React, { useRef } from 'react';
import { NavMenuItem, NavLinkMenuItem } from '../../../../../types/link';
import { KeyboardEventCode } from '../../../../utils/uiEvent';
import NavMenuOption from './NavMenuOption/NavMenuOption';
import styles from './NavMenu.module.scss';

interface NavMenuProps {
  menuItems: NavMenuItem[];
  selectedMenuItemId?: Nullable<string>;
  onSelectMenuItemId?: Nullable<(id: Nullable<string>) => void>;
  onClick: (evt: React.UIEvent<HTMLElement>, item: NavLinkMenuItem) => void;
  children?: Nullable<
    (item: NavMenuItem, isSelected: boolean) => React.ReactNode
  >;
  simplifiedMegaNav?: boolean;
}

/*
 * List of buttons/links that can be navigated via keyboard up/down arrows
 * If onSelectMenuItemId & selectedMenuItemId are provided, buttons/links are selectable
 *
 * Links trigger onSelectItemId on {Hover}/{Space}
 * Buttons trigger onSelectItemId on {Hover}/{Click}/{Space}/{Enter}
 *
 * Selectable links/buttons render as highlighted when selected and outlined when focused
 * Non-selectable links/buttons render as highlighted when hovered and focused
 */

const NavMenu = ({
  menuItems,
  selectedMenuItemId = null,
  onSelectMenuItemId = null,
  onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  children = null,
  simplifiedMegaNav = false,
}: NavMenuProps) => {
  const focusRefs = useRef<HTMLElement[]>([]);
  const handleKeyboardArrow = (directionCode: KeyboardEventCode, index) => {
    if (
      directionCode === KeyboardEventCode.ArrowUp ||
      directionCode === KeyboardEventCode.ArrowDown
    ) {
      const diff = directionCode === KeyboardEventCode.ArrowUp ? -1 : 1;
      const focusedIndex = (index + diff + menuItems.length) % menuItems.length;
      focusRefs.current[focusedIndex].focus();
    }
  };

  return (
    <ul
      data-testid="nav-menu"
      className={styles.list}
      role="menu"
    >
      {menuItems.map((item, index) => {
        const isSelected = item.id === selectedMenuItemId;

        return (
          <NavMenuOption
            key={item.id}
            ref={(element: HTMLElement) => focusRefs.current.push(element)}
            wrapperClassName={styles.listItem}
            className={styles.menuItem}
            item={item}
            isSelected={isSelected}
            onSelectItemId={onSelectMenuItemId ?? null}
            onClick={onClick}
            onKeyboardArrow={(direction: KeyboardEventCode) =>
              handleKeyboardArrow(direction, index)
            }
            simplifiedMegaNav={simplifiedMegaNav}
          >
            {!!children && children(item, isSelected)}
          </NavMenuOption>
        );
      })}
    </ul>
  );
};

export default NavMenu;
