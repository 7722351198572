import React, { useEffect, useState } from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { Link } from '@unisporkal/alliance-ui-link';
import {
  ButtonVariantEnum,
  ButtonSizeEnum,
  Button,
} from '@unisporkal/alliance-ui-button';
import { IconButton } from '@unisporkal/alliance-ui-icon-button';
import AccountIcon from '../icons/Account.svg?icon';
import { USER_PANEL_VIEW } from '../../../../constants';
import {
  openPanelTrackingEvent,
  trackNavigation,
  trackSelectLogin,
} from '../../../../tracking/navigationMenuTracking';
import useAccountPanelType from '../../../../hooks/useAccountPanelType';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';
import styles from './Account.module.scss';

interface SignInJoinProps {
  visualGpsHeader?: boolean;
  openAccountPanel: () => void;
}

const Account = ({ visualGpsHeader, openAccountPanel }: SignInJoinProps) => {
  const siteMap = useSiteMap();
  const t = useTranslations();
  const accountPanelView = useAccountPanelType();
  const {
    data: { displayName },
  } = useHeaderPropsQuery();
  const [currentLocation, setCurrentLocation] = useState('');
  const href = siteMap.signInPath(encodeURIComponent(currentLocation));

  // Classnames for link, icon, and link text correlate to header type (visualGps vs default)
  const header = visualGpsHeader ? 'visualGps' : 'default';
  const linkStyles = `${styles[`${header}SignInLink`]}`;
  const iconStyles = `${styles[`${header}SignInIcon`]}`;

  useEffect(() => {
    const returnPath = window.location.pathname;
    setCurrentLocation(returnPath);
  }, []);

  const handleOpenAccountPanel = () => {
    openAccountPanel();
    openPanelTrackingEvent('right', accountPanelView, 'open account panel');
  };

  const signInTracking = () => {
    trackSelectLogin('header');
    trackNavigation({
      name: 'nav_Account_SignIn',
      path: href,
      location: 'header',
    });
  };

  return (
    <div className={styles.account}>
      {accountPanelView === USER_PANEL_VIEW.SIGNED_OUT ? (
        <div
          data-testid="AccountView"
          className={styles.linkOrButton}
        >
          <Link
            buttonStyleProps={{
              size: ButtonSizeEnum.SMALL,
              variant: ButtonVariantEnum.HOLLOW,
            }}
            href={href}
            onClick={signInTracking}
            className={linkStyles}
            data-testid={`${header}-link`}
          >
            <span className={styles.signInText}>{t('sign_in')}</span>
          </Link>
          <IconButton
            tabIndex={0}
            ariaLabel="Open Account Panel"
            data-testid="mobile-account-button"
            onClick={handleOpenAccountPanel}
            className={`${styles[`${header}Icon`]}`}
          >
            <span className={iconStyles}>
              <AccountIcon />
            </span>
          </IconButton>
        </div>
      ) : (
        <Button
          data-testid="AccountView"
          className={!visualGpsHeader ? styles.container : styles.gpsContainer}
          onClick={handleOpenAccountPanel}
        >
          <AccountIcon />
          <span className={styles.signInName}>{displayName}</span>
        </Button>
      )}
    </div>
  );
};

export default Account;
