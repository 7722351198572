import React, { ReactNode } from 'react';
import cx from 'classnames';
import { Link } from '@unisporkal/alliance-ui-link';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import IconArrow from '../icons/Arrow.svg?icon';
import { NavLinkItem } from '../../../../../types/link';
import headerStyles from '../Header.module.scss';
import styles from './NavLinkOrButton.module.scss';

export enum NavLinkVariantEnum {
  PILL = 'pillVariant',
  LINK = 'linkVariant',
  OLD = 'oldVariant',
}

export interface NavLinkOrButtonCommonProps {
  icon?: ReactNode;
  hideText?: boolean;
  variant?: NavLinkVariantEnum;
  onClick?: (linkOrButtonText: NavLinkItem | string) => void;
  onExpandKeyboardToggle?: ((isExpanded: boolean) => void) | null;
  isExpanded?: boolean;
  className?: string;
}

interface NavLinkOrButtonProps extends NavLinkOrButtonCommonProps {
  linkOrButtonText: NavLinkItem | string;
}

const NavLinkOrButton = ({
  linkOrButtonText,
  icon = null,
  hideText = false,
  variant = NavLinkVariantEnum.OLD,
  onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onExpandKeyboardToggle = null,
  isExpanded = false,
  className = '',
}: NavLinkOrButtonProps) => {
  const classes = cx(styles.navLink, {
    [styles[variant]]: !!variant,
    [styles.isExpanded]: isExpanded,
    [headerStyles.desktopLabel]: variant === NavLinkVariantEnum.OLD,
    [className]: !!className,
  });
  const iconClasses = cx(styles.icon, {
    [styles.iconWithText]: !hideText,
  });
  const isLink = typeof linkOrButtonText !== 'string';
  const link = isLink ? (linkOrButtonText as NavLinkItem) : null;
  const text = isLink
    ? (linkOrButtonText as NavLinkItem).name
    : linkOrButtonText;

  const navigationTracking = () => {
    const ga4Event = link?.gaName
      ? navigationEvent({
          link_text: link.gaName,
          link_url: link?.href,
          navigation_selection: link.gaName,
          ui_element_location: 'header',
        })
      : undefined;
    ga4Track(ga4Event);
  };

  const handleLinkClick = () => {
    navigationTracking();
    onClick?.(linkOrButtonText);
  };

  const handleKeyDown = (evt) => {
    if (!onExpandKeyboardToggle) {
      return;
    }

    if (evt.code === 'Space') {
      evt.preventDefault();
      onExpandKeyboardToggle(true);
    } else if (evt.code === 'Escape') {
      onExpandKeyboardToggle(false);
    }
  };

  const iconEl = !!icon && (
    <span
      data-testid="nav-link-icon"
      className={iconClasses}
    >
      {icon}
    </span>
  );
  const textEl = !hideText && (
    <span
      data-testid="nav-link-text"
      className={styles.text}
    >
      {text}
    </span>
  );
  const dropdownArrowEl = !!onExpandKeyboardToggle && (
    <IconArrow
      data-testid="dropdown-arrow"
      className={styles.arrowIcon}
    />
  );
  const dropdownControlProps = onExpandKeyboardToggle
    ? { onKeyDown: handleKeyDown, 'aria-expanded': isExpanded }
    : {};

  if (isLink) {
    return (
      <Link
        href={link?.href}
        data-testid={link?.uaEventLabel}
        target={link?.target === '_blank' ? '_blank' : ''}
        rel={link?.target === '_blank' ? 'noopener noreferrer' : ''}
        onClick={handleLinkClick}
        className={classes}
        /* eslint-disable react/jsx-props-no-spreading */
        {...dropdownControlProps}
        {...(hideText ? { ariaLabel: text } : {})}
        /* eslint-enable react/jsx-props-no-spreading */
      >
        {iconEl}
        {textEl}
        {dropdownArrowEl}
      </Link>
    );
  }

  return (
    <button
      type="button"
      onClick={() => onClick?.(linkOrButtonText)}
      className={classes}
      /* eslint-disable react/jsx-props-no-spreading */
      {...dropdownControlProps}
      {...(hideText ? { 'aria-label': text } : {})}
      /* eslint-enable react/jsx-props-no-spreading */
    >
      {iconEl}
      {textEl}
      {dropdownArrowEl}
    </button>
  );
};

export default NavLinkOrButton;
