import React from 'react';
import styles from './LoadingAnimation.module.scss';

const LoadingAnimation = () => (
  <div className={styles.container}>
    <div className={styles.mainRow} />
    <div className={styles.row} />
    <div className={styles.row} />
    <div className={styles.row} />
  </div>
);

export default LoadingAnimation;
