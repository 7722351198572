import { UIEvent } from 'react';
import { navigationEvent, ga4Track } from '@unisporkal/ga4';
import { LinkItem, NavLinkItem } from 'types/link';

interface TrackNavigateProps {
  link?: LinkItem | null;
  uaUiElementLocation?: string;
  ga4UiElementLocation?: string;
  ga4NavigationSelection?: string;
  section?: string | null;
  pillLinkString?: string | null;
}

const subNavSection = (section) =>
  section === 'Trending' ? 'trending_searches' : 'popular_searches';

// eslint-disable-next-line import/prefer-default-export
const trackNavigate = ({
  link,
  ga4UiElementLocation,
  ga4NavigationSelection,
}: TrackNavigateProps) => {
  const linkText = link?.gaName;
  const linkUrl = link?.href;

  const ga4Event = linkText
    ? navigationEvent({
        link_text: linkText,
        link_url: linkUrl,
        navigation_selection: ga4NavigationSelection,
        ui_element_location: ga4UiElementLocation,
      })
    : undefined;

  ga4Track(ga4Event);
};

const trackOverviewNavigate = (
  _evt: UIEvent<HTMLElement>,
  link: LinkItem,
  navigationSelection: string
) => {
  trackNavigate({
    link,
    ga4UiElementLocation: 'header_sub_nav_link',
    ga4NavigationSelection: `${navigationSelection.toLowerCase()}`,
  });
};

const trackNavMenuNavigate = (
  _evt: UIEvent<HTMLElement>,
  link: NavLinkItem,
  ga4NavigationSelection: string,
  uiElementLocation = 'header_sub_nav'
) => {
  trackNavigate({
    link,
    ga4UiElementLocation: uiElementLocation,
    ga4NavigationSelection,
  });
};

const trackPillNavigate = (
  _evt: UIEvent<HTMLElement>,
  link: LinkItem,
  pillsSection: string,
  navSection: string
) => {
  trackNavigate({
    link,
    ga4UiElementLocation: 'header_sub_nav_link',
    ga4NavigationSelection: `${navSection.toLowerCase()}_${subNavSection(
      pillsSection
    )}`,
  });
};

const trackViewAllPillsNavigate = (
  pillsSection: string,
  navSection: string
) => {
  trackNavigate({
    link: { href: '', name: 'view_all', gaName: 'view_all' },
    ga4NavigationSelection: `${navSection.toLowerCase()}_${subNavSection(
      pillsSection
    )}`,
    ga4UiElementLocation: 'header_sub_nav_link',
  });
};

const megaNavTracking = {
  trackOverviewNavigate,
  trackNavMenuNavigate,
  trackPillNavigate,
  trackViewAllPillsNavigate,
};

export default megaNavTracking;
