import { useTranslations } from '@unisporkal/localization';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import usePopularCategories from '../usePopularCategories';

export const useEditorialEntertainment = () => {
  const trackingId = 'Editorial_Entertainment';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();
  const editorialOverviewLinks = useEditorialOverviewLinks();
  const {
    data: { editorialTrendingSearchLinksEntertainment },
  } = useHeaderPropsQuery();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialEntertainment,
    overviewText: {
      title: t('entertainment'),
      body: t('check_out_latest_entertainment'),
    },
    trendingPills: {
      title: t('trending_entertainment_searches'),
      items: editorialTrendingSearchLinksEntertainment,
    },
    popularPills: {
      title: t('popular_entertainment_categories'),
      items: editorialPopularCategoryLinks.entertainment,
    },
  };
};

export default useEditorialEntertainment;
