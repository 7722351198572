import cx from 'classnames';
import React from 'react';
import { List } from '@unisporkal/alliance-ui-list';
import { Button, ButtonVariantEnum } from '@unisporkal/alliance-ui-button';
import { useTranslations } from '@unisporkal/localization';
import { LinkItem } from '../../../../../types/link';
import MegaNavPill from '../MegaNavPill/MegaNavPill';
import TrendingSearchIcon from '../icons/TrendingHighlightsIcon.svg?icon';
import PopCategoriesIcon from '../icons/PopCategoriesIcon.svg?icon';
import styles from './MegaNavPills.module.scss';

export interface MegaNavPillsProps {
  groupTitle: string;
  links: LinkItem[];
  isTrendingSearches?: boolean;
  isViewAll: boolean;
  onViewAll?: () => void;
  onPillClick: (evt: React.UIEvent<HTMLAnchorElement>, link: LinkItem) => void;
  isMobile?: boolean;
}

const MegaNavPills = ({
  links,
  groupTitle,
  isTrendingSearches = false,
  isViewAll,
  onViewAll = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onPillClick,
  isMobile = false,
}: MegaNavPillsProps) => {
  const t = useTranslations();
  const Icon = isTrendingSearches ? TrendingSearchIcon : PopCategoriesIcon;
  const topNavPills = isViewAll ? links : links.slice(0, 6);
  const ariaLabel = isTrendingSearches
    ? t('trending_searches')
    : t('popular_categories');

  return (
    <section
      className={cx(styles.container, { [styles.isDesktop]: !isMobile })}
    >
      <h6 className={styles.header}>
        <span className={styles.title}>{groupTitle}</span>
        <Icon
          className={styles.icon}
          aria-label={ariaLabel}
          data-testid="icon"
        />
      </h6>
      <List className={styles.pillContainer}>
        {topNavPills.map((link) => (
          <MegaNavPill
            key={link.gaName}
            link={link}
            onClick={(evt) => onPillClick(evt, link)}
          />
        ))}
        {isViewAll || links.length < 7 ? null : (
          <li>
            <Button
              variant={ButtonVariantEnum.HOLLOW}
              data-testid="view-all"
              onClick={onViewAll}
              className={styles.viewAll}
              aria-label={`${t('view_all')} ${ariaLabel}`}
            >
              {t('view_all')}
            </Button>
            <Button
              variant={ButtonVariantEnum.TEXT}
              data-testid="view-more"
              onClick={onViewAll}
              className={`${styles.viewMore} ${styles.viewMoreText}`}
              aria-label={`${t('view_more')} ${ariaLabel}`}
            >
              {t('view_more')}
            </Button>
          </li>
        )}
      </List>
    </section>
  );
};

export default MegaNavPills;
