import { useTranslations } from '@unisporkal/localization';
import { useNavLinks } from '../useNavLinks';
import { OverviewLink } from '../useOverviewLinks';

type Link = {
  href: string;
  name: string;
  gaName?: string;
};
interface AIGeneratorMenuContent {
  trackingId: string;
  overviewLink: OverviewLink;
  overviewText: {
    title: string;
    body: string;
  };
  secondaryLinks?: Link[];
}
const useAIGenerator = () => {
  const t = useTranslations();
  const links = useNavLinks();

  const pricing: AIGeneratorMenuContent = {
    trackingId: 'AI_Generator_Overview_And_Pricing',
    overviewLink: {
      href: links.aiGeneratorPricing.href,
      name: links.aiGeneratorPricing.name,
      gaName: 'overview_and_pricing',
    },
    overviewText: {
      title: links.aiGeneratorPricing.name,
      body: t('experience_generative_ai_by_getty'),
    },
    secondaryLinks: [
      {
        href: links.aiNewFeatures.href,
        name: links.aiNewFeatures.name,
        gaName: 'whats_new',
      },
      {
        href: links.aiPlansAndPricingFaq.href,
        name: links.aiPlansAndPricingFaq.name,
        gaName: 'faqs',
      },
    ],
  };

  const generate: AIGeneratorMenuContent = {
    trackingId: 'AI_Generator_Generate_New_Images',
    overviewLink: {
      href: links.aiGenerateNewImages.href,
      name: t('learn_more_string'),
      gaName: 'learn_more',
    },
    overviewText: {
      title: links.aiGenerateNewImages.name,
      body: t('text_into_safe_images_with_ai_generator'),
    },
    secondaryLinks: [
      {
        href: links.aiGeneratorUserGuide.href,
        name: links.aiGeneratorUserGuide.name,
        gaName: 'user_guide',
      },
      {
        href: links.aiGenerateNewImagesFaq.href,
        name: links.aiGenerateNewImagesFaq.name,
        gaName: 'faqs',
      },
    ],
  };

  const modify: AIGeneratorMenuContent = {
    trackingId: 'AI_Generator_Modify_Creative_Images',
    overviewLink: {
      href: links.aiGeneratorModify.href,
      name: t('learn_more_string'),
      gaName: 'learn_more',
    },
    overviewText: {
      title: links.aiGeneratorModify.name,
      body: t('add_remove_replace_elements'),
    },
    secondaryLinks: [
      {
        href: links.aiModifyCreativeImagesFaq.href,
        name: links.aiModifyCreativeImagesFaq.name,
        gaName: 'faqs',
      },
    ],
  };

  const custom: AIGeneratorMenuContent = {
    trackingId: 'AI_Generator_Custom_Fine-Tuning',
    overviewLink: {
      href: links.aiGeneratorCustom.href,
      name: t('learn_more_string'),
      gaName: 'learn_more',
    },
    overviewText: {
      title: links.aiGeneratorCustom.name,
      body: t('generate_consistent_on_brand_visuals'),
    },
  };

  return {
    pricing,
    generate,
    modify,
    custom,
  };
};

export default useAIGenerator;
