import cx from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './SidebarPanel.module.scss';

interface SidebarPanelProps {
  isVisible: boolean;
  children?: React.ReactNode;
  className?: string;
}

const SidebarPanel = ({
  isVisible,
  className = '',
  children,
}: SidebarPanelProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll panel to top before showing
  useEffect(() => {
    if (isVisible) {
      containerRef.current?.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [isVisible]);

  return (
    <div
      data-testid="meganav-panel"
      ref={containerRef}
      className={cx(styles.panel, className, {
        [styles.delayedHidden]: !isVisible,
      })}
    >
      {children}
    </div>
  );
};

export default SidebarPanel;
