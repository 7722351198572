import { useTranslations } from '@unisporkal/localization';
import { AssetCategoryType } from '@unisporkal/sites';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import { useCreativeOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';
import useCuratedContent from './useCuratedContent';

export const useCreativeImages = () => {
  const trackingId = 'Creative_Images';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const curatedContent = useCuratedContent();
  const creativePopularCategoryLinks =
    popularCategories.creativePopularCategoryLinks();
  const curatedContentLinks = curatedContent.getSeasonalContentLinks(
    AssetCategoryType.IMAGES
  );

  const {
    data: { creativeTrendingSearchLinksImage },
  } = useHeaderPropsQuery();

  const creativeOverviewLinks = useCreativeOverviewLinks();

  return {
    trackingId,
    overviewLink: creativeOverviewLinks.creativeImages,
    topSearchesLink: creativeOverviewLinks.topCreativeImageSearches,
    overviewText: {
      title: t('creative_images'),
      body: t('browse_millions_exclusive_visuals'),
    },
    curatedPills: {
      title: t('curated_by_getty_images'),
      items: curatedContentLinks,
    },
    trendingPills: {
      title: t('trending_image_searches'),
      items: creativeTrendingSearchLinksImage,
    },
    popularPills: {
      title: t('popular_image_categories'),
      items: creativePopularCategoryLinks.photos,
    },
  };
};

export default useCreativeImages;
