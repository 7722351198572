/* eslint-disable complexity */
import React from 'react';
import { LinkItem, PillsData } from '../../../../../types/link';
import { PILLTYPES } from '../../../../constants';
import MegaNavContent from '../MegaNavContent/MegaNavContent';
import MegaNavPills from '../MegaNavPills/MegaNavPills';
import SidebarPanel from '../SidebarMegaNav/SidebarPanel/SidebarPanel';
import { OverviewText } from '../models/MegaNavOverviewText';

interface MobileMegaNavContentProps {
  overviewText: Omit<OverviewText, 'id'>;
  overviewLink: LinkItem;
  topSearchesLink?: LinkItem;
  onOverviewLinkClick: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    selectedNavItemId?: string
  ) => void;
  onTopSearchesLinkClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    selectedNavItemId?: string
  ) => void;
  onSecondaryLinkClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    selectedNavItemId?: string
  ) => void;
  curatedPills?: PillsData;
  trendingPills?: PillsData;
  popularPills?: PillsData;
  isViewAllCuratedPills?: boolean;
  isViewAllTrendingPills?: boolean;
  isViewAllPopularPills?: boolean;
  secondaryLinks?: LinkItem[];
  onViewAllPillsClick?: (category: string) => void;
  onPillClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    section: string,
    selectedNavItemId?: string
  ) => void;
}

const MobileMegaNavContent = ({
  overviewText,
  overviewLink,
  topSearchesLink,
  curatedPills,
  trendingPills,
  popularPills,
  secondaryLinks,
  isViewAllCuratedPills = false,
  isViewAllTrendingPills = false,
  isViewAllPopularPills = false,
  onOverviewLinkClick,
  onTopSearchesLinkClick,
  onSecondaryLinkClick,
  onViewAllPillsClick,
  onPillClick,
}: MobileMegaNavContentProps) => (
  <>
    <SidebarPanel
      isVisible={
        !isViewAllTrendingPills &&
        !isViewAllPopularPills &&
        !isViewAllCuratedPills
      }
    >
      <MegaNavContent
        overviewLink={overviewLink}
        overviewText={overviewText}
        topSearchesLink={topSearchesLink}
        curatedPills={curatedPills}
        trendingPills={trendingPills}
        popularPills={popularPills}
        secondaryLinks={secondaryLinks}
        onPillClick={onPillClick}
        onOverviewLinkClick={onOverviewLinkClick}
        onTopSearchesLinkClick={onTopSearchesLinkClick}
        onSecondaryLinkClick={onSecondaryLinkClick}
        isViewAllCuratedPills={isViewAllCuratedPills}
        isViewAllTrendingPills={false}
        isViewAllPopularPills={false}
        onViewAllPillsClick={onViewAllPillsClick}
        isMobile
      />
    </SidebarPanel>

    <SidebarPanel
      isVisible={
        isViewAllTrendingPills || isViewAllPopularPills || isViewAllCuratedPills
      }
    >
      {!!curatedPills && isViewAllCuratedPills && (
        <MegaNavPills
          key={curatedPills?.title}
          groupTitle={curatedPills?.title ?? ''}
          links={curatedPills?.items ?? []}
          isViewAll
          onPillClick={(evt, link) =>
            onPillClick?.(evt, link, PILLTYPES.CURATED)
          }
          isMobile
        />
      )}

      {!!trendingPills && isViewAllTrendingPills && (
        <MegaNavPills
          key={trendingPills?.title}
          groupTitle={trendingPills?.title ?? ''}
          links={trendingPills?.items ?? []}
          isViewAll
          onPillClick={(evt, link) =>
            onPillClick?.(evt, link, PILLTYPES.TRENDING)
          }
          isTrendingSearches
          isMobile
        />
      )}

      {!!popularPills && isViewAllPopularPills && (
        <MegaNavPills
          key={popularPills?.title}
          groupTitle={popularPills?.title ?? ''}
          links={popularPills?.items ?? []}
          isViewAll
          onPillClick={(evt, link) =>
            onPillClick?.(evt, link, PILLTYPES.POPULAR)
          }
          isMobile
        />
      )}
    </SidebarPanel>
  </>
);

export default MobileMegaNavContent;
