import React from 'react';
import { TSiteMap, useSiteMap } from '@unisporkal/sites';
import { useTranslations } from '@unisporkal/localization';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { Link } from '@unisporkal/alliance-ui-link';
import headerStyles from '../Header.module.scss';
import styles from './AIGeneratorLink.module.scss';
// eslint-disable-next-line import/no-unresolved
import AIGeneratorIcon from '../../shared/icons/AIGenerator.svg?icon';
import useHeaderPropsQuery from '../../../../hooks/queries/useHeaderPropsQuery';

interface AIGeneratorLinkProps {
  className?: string;
}

export const getAiGeneratorHref = (
  hasActiveGenerationAgreement: boolean,
  siteMap: TSiteMap,
  showAiGenPreShotMerchandising: boolean
): string => {
  if (hasActiveGenerationAgreement) {
    return siteMap.aiGenerator();
  }
  if (showAiGenPreShotMerchandising) {
    return siteMap.aiGenerationPricing();
  }
  return siteMap.aiGenerationLanding();
};

const AIGeneratorLink = ({ className = '' }: AIGeneratorLinkProps) => {
  const {
    data: { hasActiveGenerationAgreement, showAiGenPreShotMerchandising },
  } = useHeaderPropsQuery();
  const siteMap = useSiteMap();
  const t = useTranslations();
  const href = getAiGeneratorHref(
    hasActiveGenerationAgreement,
    siteMap,
    showAiGenPreShotMerchandising
  );

  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: 'ai_generator',
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: 'ai_generator',
    });
    ga4Track(ga4Event);
  };

  return (
    <Link
      href={href}
      data-nav="nav_AI_Generator"
      data-testid="nav_AI_Generator"
      className={`${headerStyles.allianceOverrides} ${styles.link} ${className}`}
      onClick={navigationTracking}
    >
      <AIGeneratorIcon />
      <span
        className={`${headerStyles.desktopLabel} ${styles.aiGeneratorText}`}
      >
        {t('ai_generator_title_casing')}
      </span>
    </Link>
  );
};

export default AIGeneratorLink;
