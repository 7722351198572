import React, { useState, useEffect, useMemo } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { useNavLinks } from '../../../../../hooks/getty/useNavLinks';
import { NavMenuItem } from '../../../../../../types/link';
import { PILLTYPES } from '../../../../../constants';
import MegaNav from '../../MegaNav/MegaNav';
import megaNavTracking from '../../Tracking/Tracking';
import useEditorialImages from '../../../../../hooks/getty/megaNav/useEditorialImages';
import useEditorialVideos from '../../../../../hooks/getty/megaNav/useEditorialVideos';
import useEditorialNews from '../../../../../hooks/getty/megaNav/useEditorialNews';
import useEditorialSports from '../../../../../hooks/getty/megaNav/useEditorialSports';
import useEditorialEntertainment from '../../../../../hooks/getty/megaNav/useEditorialEntertainment';
import useEditorialFashion from '../../../../../hooks/getty/megaNav/useEditorialFashion';
import useEditorialUserGenerated from '../../../../../hooks/getty/megaNav/useEditorialUserGenerated';
import useEditorialArchive from '../../../../../hooks/getty/megaNav/useEditorialArchive';
import MegaNavContent from '../../MegaNavContent/MegaNavContent';

interface EditorialMegaNavProps {
  isOpen: boolean;
}

const EditorialMegaNav = ({ isOpen }: EditorialMegaNavProps) => {
  const t = useTranslations();
  const groupTitle = t('editorial_content');
  const links = useNavLinks();
  const image = useEditorialImages();
  const video = useEditorialVideos();
  const news = useEditorialNews();
  const sports = useEditorialSports();
  const entertainment = useEditorialEntertainment();
  const fashion = useEditorialFashion();
  const userGen = useEditorialUserGenerated();
  const archive = useEditorialArchive();

  const [selectedNavItemId, setSelectedNavItemId] = useState<string>(
    links.editorialImages.id
  );
  const [isViewAllPopularPills, setIsViewAllPopularPills] = useState(false);

  const [isViewAllTrendingPills, setIsViewAllTrendingPills] = useState(false);

  const handleViewAllPillsClick =
    (trackingId: string) => (pillType: string) => {
      megaNavTracking.trackViewAllPillsNavigate(pillType, trackingId);

      switch (pillType) {
        case PILLTYPES.POPULAR:
          return setIsViewAllPopularPills(true);
        case PILLTYPES.TRENDING:
          return setIsViewAllTrendingPills(true);
        default:
          return null;
      }
    };
  const trackPillClick = (trackingId) => (event, link, section) =>
    megaNavTracking.trackPillNavigate(event, link, section, trackingId);
  const trackOverviewLinkClick = (trackingId) => (event, link) =>
    megaNavTracking.trackOverviewNavigate(event, link, trackingId);

  const linkWithContent = (link, content) => ({
    ...link,
    content: (
      <MegaNavContent
        overviewLink={content.overviewLink}
        overviewText={content.overviewText}
        trendingPills={content.trendingPills}
        popularPills={content.popularPills}
        onPillClick={trackPillClick(content.trackingId)}
        onOverviewLinkClick={trackOverviewLinkClick(content.trackingId)}
        isViewAllTrendingPills={isViewAllTrendingPills}
        isViewAllPopularPills={isViewAllPopularPills}
        onViewAllPillsClick={handleViewAllPillsClick(content.trackingId)}
      />
    ),
  });

  // Reset isViewAll state when selectedNavItemId changes
  useEffect(() => {
    setIsViewAllPopularPills(false);
    setIsViewAllTrendingPills(false);
  }, [selectedNavItemId]);

  const navItems: NavMenuItem[] = useMemo(
    () => [
      linkWithContent(links.editorialImages, image),
      linkWithContent(links.editorialVideos, video),
      linkWithContent(links.editorialNews, news),
      linkWithContent(links.editorialSports, sports),
      linkWithContent(links.editorialEntertainment, entertainment),
      linkWithContent(links.editorialFashion, fashion),
      linkWithContent(links.editorialUserGenerated, userGen),
      linkWithContent(links.editorialArchive, archive),
    ],
    [isViewAllPopularPills, isViewAllTrendingPills]
  );

  return (
    <MegaNav
      isOpen={isOpen}
      groupTitle={groupTitle}
      navItems={navItems}
      selectedNavItemId={selectedNavItemId}
      onSelectNavItemId={setSelectedNavItemId}
      onNavigate={(event, link) =>
        megaNavTracking.trackNavMenuNavigate(event, link, 'editorial')
      }
    />
  );
};

export default EditorialMegaNav;
