import { createContext } from 'react';

export interface OverlayContextValue {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default createContext<OverlayContextValue>({
  isOpen: false,
  setIsOpen: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});
