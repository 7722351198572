import { useTranslations } from '@unisporkal/localization';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import usePopularCategories from '../usePopularCategories';

export const useEditorialArchive = () => {
  const trackingId = 'Editorial_Archive';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();
  const editorialOverviewLinks = useEditorialOverviewLinks();
  const {
    data: { editorialTrendingSearchLinksArchive },
  } = useHeaderPropsQuery();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialArchive,
    overviewText: {
      title: t('archives'),
      body: t('browse_largest_digital_archive_getty'),
    },
    trendingPills: {
      title: t('trending_archival_searches'),
      items: editorialTrendingSearchLinksArchive,
    },
    popularPills: {
      title: t('popular_archives_categories'),
      items: editorialPopularCategoryLinks.archives,
    },
  };
};

export default useEditorialArchive;
