import cx from 'classnames';
import React from 'react';
import { LinkItem, PillsData } from '../../../../../types/link';
import { PILLTYPES } from '../../../../constants';
import { OverviewText } from '../models/MegaNavOverviewText';
import MegaNavOverview from './MegaNavOverview/MegaNavOverview';
import MegaNavPills from '../MegaNavPills/MegaNavPills';
import styles from './MegaNavContent.module.scss';

interface MegaNavContentProps {
  overviewText: Omit<OverviewText, 'id'>;
  overviewLink: LinkItem;
  topSearchesLink?: LinkItem;
  secondaryLinks?: LinkItem[];
  onOverviewLinkClick: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    selectedNavItemId?: string
  ) => void;
  onTopSearchesLinkClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    selectedNavItemId?: string
  ) => void;
  onSecondaryLinkClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    selectedNavItemId?: string
  ) => void;
  curatedPills?: PillsData;
  trendingPills?: PillsData;
  popularPills?: PillsData;
  isViewAllCuratedPills?: boolean;
  isViewAllTrendingPills?: boolean;
  isViewAllPopularPills?: boolean;
  onViewAllPillsClick?: (category: string) => void;
  onPillClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem,
    section: string,
    selectedNavItemId?: string
  ) => void;
  isMobile?: boolean;
}

// eslint-disable-next-line complexity
const MegaNavContent = ({
  overviewText,
  overviewLink,
  topSearchesLink,
  secondaryLinks,
  onOverviewLinkClick,
  onTopSearchesLinkClick,
  onSecondaryLinkClick,
  curatedPills,
  trendingPills,
  popularPills,
  isViewAllCuratedPills = false,
  isViewAllTrendingPills = false,
  isViewAllPopularPills = false,
  onViewAllPillsClick,
  onPillClick,
  isMobile = false,
}: MegaNavContentProps) => (
  <div className={cx(styles.content, { [styles.isDesktop]: !isMobile })}>
    <MegaNavOverview
      className={styles.overview}
      title={overviewText.title}
      body={overviewText.body}
      overviewLink={overviewLink}
      topSearchesLink={topSearchesLink}
      secondaryLinks={secondaryLinks}
      onOverviewLinkClick={onOverviewLinkClick}
      onTopSearchesLinkClick={onTopSearchesLinkClick}
      onSecondaryLinkClick={onSecondaryLinkClick}
    />
    {(!!trendingPills?.items?.length ||
      !!popularPills?.items?.length ||
      !!curatedPills?.items?.length) && (
      <div
        data-testid="mega-nav-pills"
        className={styles.pillsWrapper}
      >
        {!!curatedPills?.items?.length && (
          <div
            data-testid="curated-content-pills"
            className={styles.pillsContainer}
          >
            <MegaNavPills
              key={curatedPills.title}
              groupTitle={curatedPills.title}
              links={curatedPills.items}
              isViewAll={isViewAllCuratedPills ?? false}
              onViewAll={() => onViewAllPillsClick?.(PILLTYPES.CURATED)}
              onPillClick={(evt, link) =>
                onPillClick?.(evt, link, PILLTYPES.CURATED)
              }
              isMobile={isMobile}
            />
          </div>
        )}
        {!!trendingPills?.items?.length && (
          <div
            data-testid="trending-searches-pills"
            className={styles.pillsContainer}
          >
            <MegaNavPills
              key={trendingPills.title}
              groupTitle={trendingPills.title}
              links={trendingPills.items}
              isViewAll={isViewAllTrendingPills}
              onViewAll={() => onViewAllPillsClick?.(PILLTYPES.TRENDING)}
              onPillClick={(evt, link) =>
                onPillClick?.(evt, link, PILLTYPES.TRENDING)
              }
              isTrendingSearches
              isMobile={isMobile}
            />
          </div>
        )}
        {!!popularPills?.items?.length && (
          <div
            data-testid="popular-categories-pills"
            className={styles.pillsContainer}
          >
            <MegaNavPills
              key={popularPills.title}
              groupTitle={popularPills.title}
              links={popularPills.items}
              isViewAll={isViewAllPopularPills ?? false}
              onViewAll={() => onViewAllPillsClick?.(PILLTYPES.POPULAR)}
              onPillClick={(evt, link) =>
                onPillClick?.(evt, link, PILLTYPES.POPULAR)
              }
              isMobile={isMobile}
            />
          </div>
        )}
      </div>
    )}
  </div>
);

export default MegaNavContent;
