import React, { forwardRef, ForwardedRef, useState } from 'react';
import cx from 'classnames';
import { ListItem } from '@unisporkal/alliance-ui-list';
import { Link } from '@unisporkal/alliance-ui-link';
import {
  NavButtonMenuItem,
  NavLinkMenuItem,
  NavMenuItem,
} from '../../../../../../types/link';
import { KeyboardEventCode } from '../../../../../utils/uiEvent';
import CaretRight from '../../icons/caretRight.svg?icon';

import styles from './NavMenuOption.module.scss';

interface NavMenuOptionProps {
  item: NavMenuItem;
  isSelected?: boolean;
  onSelectItemId?: Nullable<(id: Nullable<string>) => void>;
  disableSelectOnHover?: boolean;
  onKeyboardArrow?: (direction: KeyboardEventCode) => void;
  onClick?: (evt: React.UIEvent<HTMLElement>, item: NavMenuItem) => void;
  wrapperClassName?: string;
  className?: string;
  children?: React.ReactNode;
  simplifiedMegaNav?: boolean;
}

/*
 * Renders a button or link within a list item
 * If onSelectItemId & isSelected is provided, link/button is selectable
 *
 * Links trigger onSelectItemId on {Hover}/{Space}
 * Buttons trigger onSelectItemId on {Hover}/{Click}/{Space}/{Enter}
 * Select on hover can be disabled with disableSelectOnHover
 *
 * Selectable links/buttons render as highlighted when selected and outlined when focused (Eg. MegaNav/MobileMegaNav)
 * Non-selectable links/buttons render as highlighted when hovered and focused (Eg. NavMenuDropdown)
 */

const NavMenuOption = forwardRef(
  (
    {
      item,
      isSelected = false,
      onSelectItemId = null,
      onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      disableSelectOnHover = false,
      onKeyboardArrow = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
      wrapperClassName = '',
      className = '',
      children = null,
      simplifiedMegaNav = false,
    }: NavMenuOptionProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const [isLinkEnterKeyPress, setIsLinkEnterKeyPress] = useState(false);
    const isLink = item.hasOwnProperty('href'); // eslint-disable-line no-prototype-builtins
    const disableSelect = !onSelectItemId;
    const classes = cx(styles.menuItem, className, {
      [styles.menuItemSelected]: isSelected || isLinkEnterKeyPress,
      [styles.enableSelect]: !disableSelect,
      [styles.disableSelect]: disableSelect,
      [styles.simplifiedMegaNav]: simplifiedMegaNav,
    });
    const hoverProps = !disableSelectOnHover
      ? {
          onMouseEnter: () => onSelectItemId?.(item.id),
          onMouseLeave: () => onSelectItemId?.(null),
        }
      : {};
    const selectProps = !disableSelect
      ? {
          'aria-expanded': isSelected,
          'aria-haspopup': true,
          ...hoverProps,
        }
      : {};

    const handleClick = (
      evt: React.MouseEvent<HTMLElement>,
      option: NavMenuItem
    ) => {
      onSelectItemId?.(option.id);
      onClick?.(evt, option);
    };
    const handleKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
      if (isLink && evt.code === 'Enter') {
        // To set correct style on link while {Enter} is pressed
        setIsLinkEnterKeyPress(true);
      }

      if (evt.code === 'Space' && !disableSelect) {
        evt.preventDefault();
        onSelectItemId?.(item.id);
      } else if (evt.code in KeyboardEventCode) {
        evt.preventDefault();
        onKeyboardArrow?.(evt.code as KeyboardEventCode);
      }
    };

    const handleKeyUp = (evt: React.KeyboardEvent<HTMLElement>) => {
      if (isLink && evt.code === 'Enter') {
        setIsLinkEnterKeyPress(false);
      }
    };

    if (isLink) {
      const linkItem = item as NavLinkMenuItem;
      const label = linkItem.label ?? linkItem.name;
      return (
        <ListItem className={wrapperClassName ?? ''}>
          <Link
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            data-testid="nav-menu-option-link"
            data-ui-location="header_sub_nav"
            role="menuitem"
            className={classes}
            href={linkItem.href}
            target={linkItem.target}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            onClick={(evt) => handleClick(evt, linkItem)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...selectProps}
          >
            <span>{label}</span>
            {!disableSelectOnHover && !disableSelect && (
              <CaretRight className={styles.caret} />
            )}
          </Link>
          {children}
        </ListItem>
      );
    }

    return (
      <ListItem className={wrapperClassName ?? ''}>
        <button
          ref={ref as ForwardedRef<HTMLButtonElement>}
          data-testid="nav-menu-option-button"
          type="button"
          role="menuitem"
          className={classes}
          onKeyDown={handleKeyDown}
          onClick={(evt) => handleClick(evt, item as NavButtonMenuItem)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...selectProps}
        >
          <span>{item.name}</span>
          <CaretRight className={styles.caret} />
        </button>
        {children}
      </ListItem>
    );
  }
);

NavMenuOption.displayName = 'NavMenuOption';

export default NavMenuOption;
