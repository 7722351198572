import React, { useMemo } from 'react';
import OverlayContext, { OverlayContextValue } from './OverlayContext';

interface OverlayProviderProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
}

function OverlayProvider({
  isOpen,
  setIsOpen,
  children,
}: OverlayProviderProps) {
  const value = useMemo<OverlayContextValue>(
    () => ({ isOpen, setIsOpen }),
    [isOpen, setIsOpen]
  );
  return (
    <OverlayContext.Provider value={value}>{children}</OverlayContext.Provider>
  );
}

export default OverlayProvider;
