import { useTranslations } from '@unisporkal/localization';
import { useCollectionsOverviewLinks } from '../useOverviewLinks';

export const useExpertCurations = () => {
  const trackingId = 'Collections_Expert_Curations';
  const t = useTranslations();
  const collectionsOverviewLinks = useCollectionsOverviewLinks();

  return {
    trackingId,
    overviewLink: collectionsOverviewLinks.expertCurations,
    overviewText: {
      title: t('expert_curations'),
      body: t('browse_trending_images_and_videos_backed_by_VisualGPS'),
    },
  };
};

export default useExpertCurations;
