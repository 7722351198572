import React, { useEffect, useRef, useState, MutableRefObject } from 'react';
import cx from 'classnames';
import { useTranslations } from '@unisporkal/localization';
import { Button, ButtonVariantEnum } from '@unisporkal/alliance-ui-button';
import {
  hasChildNavMenu,
  NavLinkMenuItem,
  NavMenuItem,
} from '../../../../../types/link';
import { KeyboardEventCode } from '../../../../utils/uiEvent';
import ArrowBold from '../icons/ArrowBold.svg?icon';
import SidebarNavItem from './SidebarNavItem/SidebarNavItem';
import SidebarPanel from './SidebarPanel/SidebarPanel';
import styles from './SidebarMegaNav.module.scss';

// TODO: Remove variants once interim browse panel is replaced
export enum SidebarMegaNavVariant {
  DEFAULT = 'default',
  INTERIM = 'interim',
}

interface SidebarMegaNavProps {
  navItems: hasChildNavMenu<NavMenuItem>[];
  panelPath?: string[]; // Eg. ["creative", "images", "Trending"]
  setPanelPath: (ids: string[]) => void;
  onNavigate: (
    evt: React.UIEvent<HTMLAnchorElement>,
    item: NavLinkMenuItem,
    navigationSelection: string,
    parentItem: Nullable<NavMenuItem>
  ) => void;
  variant?: SidebarMegaNavVariant;
}

const SidebarMegaNav = ({
  navItems,
  panelPath = [],
  setPanelPath,
  onNavigate,
  variant = SidebarMegaNavVariant.DEFAULT,
}: SidebarMegaNavProps) => {
  const t = useTranslations();
  const [isResizing, setIsResizing] = useState(false);
  const resizeId = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);
  const parentNavItemRefs = useRef<HTMLElement[]>([]);
  const childNavItemRefs = useRef<HTMLElement[]>([]);
  const secondPanelId = panelPath?.[0];
  const thirdPanelId = panelPath?.[1];
  const selectedParentItem = navItems.find((item) => item.id === secondPanelId);
  const childNavMenu = selectedParentItem?.childNavMenu;
  const selectedChildItem = childNavMenu?.find(
    (item) => item.id === thirdPanelId
  );
  childNavItemRefs.current = [];

  // Add refs to nav menu options
  const addReftoArray = (element, refs: MutableRefObject<HTMLElement[]>) => {
    refs.current.push(element);
  };

  // Control focus of nav menu options with keyboard
  const handleKeyboardArrow = (
    directionCode: KeyboardEventCode,
    refs: MutableRefObject<HTMLElement[]>,
    index: number
  ) => {
    if (
      directionCode === KeyboardEventCode.ArrowUp ||
      directionCode === KeyboardEventCode.ArrowDown
    ) {
      const menuSize = refs.current.length;
      const diff = directionCode === KeyboardEventCode.ArrowUp ? -1 : 1;
      const focusedIndex = (index + diff + menuSize) % menuSize;
      refs.current[focusedIndex].focus();
    }
  };

  const handleSelectNavItem = (id: string, pathIndex: number) => {
    // Add ID to panel path
    setPanelPath([...panelPath.slice(0, pathIndex), id]);
  };
  const handleBackClick = () => {
    // Remove last ID from panel path
    setPanelPath(panelPath.slice(0, -1));
  };

  const handleResize = () => {
    setIsResizing(true);

    if (resizeId.current) {
      clearTimeout(resizeId.current);
    }

    resizeId.current = setTimeout(() => {
      setIsResizing(false);
    }, 250);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!!panelPath.length && (
        <Button
          data-testid="back-button"
          variant={ButtonVariantEnum.TEXT}
          className={cx(styles.backButton, {
            [styles.interimBackButton]:
              variant === SidebarMegaNavVariant.INTERIM,
          })} // TODO: Remove variant style once interim browse panel is replaced
          onClick={handleBackClick}
          title={t('back')}
        >
          <ArrowBold />
        </Button>
      )}

      <nav
        data-testid="sidebar-mega-nav"
        className={cx(styles.container, styles[variant])} // TODO: Remove variant style once interim browse panel is replaced
      >
        <div
          className={cx(styles.panels, {
            [styles.slideToPanel2]: panelPath.length === 1,
            [styles.slideToPanel3]: panelPath.length === 2,
            [styles.slideToPanel4]: panelPath.length === 3,
            [styles.slideToPanel5]: panelPath.length === 4,
            [styles.disableAnimation]: isResizing,
          })}
        >
          <SidebarPanel
            className={cx({
              [styles.interimPanel]: variant === SidebarMegaNavVariant.INTERIM,
            })} // TODO: Remove once interim browse panel is replaced
            isVisible={panelPath.length === 0}
          >
            <ul
              data-testid="nav-menu"
              role="menu"
              className={cx(styles.list)}
            >
              {navItems.map(
                (item: hasChildNavMenu<NavLinkMenuItem>, index: number) => (
                  <SidebarNavItem
                    key={item.id}
                    ref={(element) => addReftoArray(element, parentNavItemRefs)}
                    item={item}
                    isSelected={item.id === secondPanelId}
                    onSelectItemId={(id) => handleSelectNavItem(id, 0)}
                    onNavigate={(evt, link) =>
                      onNavigate(evt, link, item.gaName, null)
                    }
                    onKeyboardArrow={(direction: KeyboardEventCode) =>
                      handleKeyboardArrow(direction, parentNavItemRefs, index)
                    }
                  />
                )
              )}
            </ul>
          </SidebarPanel>

          {!!childNavMenu && (
            <SidebarPanel
              className={cx({
                [styles.interimPanel]:
                  variant === SidebarMegaNavVariant.INTERIM,
              })} // TODO: Remove once interim browse panel is replaced
              isVisible={panelPath.length === 1}
            >
              <ul
                data-testid="child-nav-menu"
                role="menu"
                className={cx(styles.list)}
              >
                {childNavMenu.map(
                  (childItem: NavMenuItem, childIndex: number) => (
                    <SidebarNavItem
                      key={childItem.id}
                      ref={(element) =>
                        addReftoArray(element, childNavItemRefs)
                      }
                      item={childItem}
                      isSelected={childItem.id === thirdPanelId}
                      onSelectItemId={(id) => handleSelectNavItem(id, 1)}
                      onNavigate={(evt, link) =>
                        onNavigate(
                          evt,
                          link,
                          (selectedParentItem as NavLinkMenuItem).gaName,
                          selectedParentItem
                        )
                      }
                      onKeyboardArrow={(direction: KeyboardEventCode) =>
                        handleKeyboardArrow(
                          direction,
                          childNavItemRefs,
                          childIndex
                        )
                      }
                    />
                  )
                )}
              </ul>
            </SidebarPanel>
          )}

          {(selectedChildItem || selectedParentItem)?.content}
        </div>
      </nav>
    </>
  );
};

export default SidebarMegaNav;
