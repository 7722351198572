import { useTranslations } from '@unisporkal/localization';
import { useEnterpriseOverviewLinks } from '../useOverviewLinks';

export const useCustomContent = () => {
  const trackingId = 'Custom_Content';
  const t = useTranslations();
  const EnterpriseOverviewLinks = useEnterpriseOverviewLinks();

  return {
    trackingId,
    overviewLink: EnterpriseOverviewLinks.customContent,
    overviewText: {
      title: t('custom_content'),
      body: t('custom_content_data_driven_insights'),
    },
  };
};

export default useCustomContent;
