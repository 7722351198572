import { useTranslations } from '@unisporkal/localization';
import { useCollectionsOverviewLinks } from '../useOverviewLinks';

export const useMediaAndSportsCoverage = () => {
  const trackingId = 'Collections_Expert_Curations';
  const t = useTranslations();
  const collectionsOverviewLinks = useCollectionsOverviewLinks();

  return {
    trackingId,
    overviewLink: collectionsOverviewLinks.mediaAndSportsCoverage,
    overviewText: {
      title: t('media_and_sports_coverage'),
      body: t('discover_global_news_sports_entertainment_archive_visuals'),
    },
  };
};

export default useMediaAndSportsCoverage;
