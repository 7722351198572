import React, { useRef } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { Button, ButtonVariantEnum } from '@unisporkal/alliance-ui-button';
import { openPanelTrackingEvent } from '../../../../tracking/navigationMenuTracking';
import { PANELTYPES } from '../../../../constants';
import HamburgerIcon from '../icons/Hamburger.svg?icon';
import styles from './Hamburger.module.scss';

interface HamburgerButtonProps {
  isPanelOpen: boolean;
  onOpenPanel: () => void;
}

export const HamburgerButton = ({
  isPanelOpen,
  onOpenPanel,
}: HamburgerButtonProps) => {
  const t = useTranslations();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const onBrowseClick = () => {
    openPanelTrackingEvent('left', PANELTYPES.BROWSE, 'open menu');
    onOpenPanel();
    buttonRef.current?.blur();
  };

  return (
    <Button
      ref={buttonRef}
      data-testid="hamburger-menu"
      variant={ButtonVariantEnum.SECONDARY}
      aria-haspopup="menu"
      aria-expanded={isPanelOpen}
      aria-label={t('open_main_navigation_menu')}
      title={t('open_menu')}
      onClick={onBrowseClick}
      className={styles.container}
    >
      <HamburgerIcon />
    </Button>
  );
};

export default HamburgerButton;
