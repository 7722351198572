import React from 'react';
import { useSiteMap } from '@unisporkal/sites';
import { ga4Track, navigationEvent } from '@unisporkal/ga4';
import { Link } from '@unisporkal/alliance-ui-link';
import headerStyles from '../Header.module.scss';
import useCartCount from '../../../../hooks/queries/useCartCount';
import styles from './CartLink.module.scss';
import CartIcon from '../icons/Cart.svg?icon';

export const CartLinkContent = () => {
  const { data: cartCount } = useCartCount();

  const showCartCountBadge = cartCount > 0;
  const showCartCount = () => {
    if (cartCount > 99) {
      return '99+';
    }
    return cartCount;
  };

  if (showCartCountBadge) {
    return (
      <div className={styles.iconWrapper}>
        <CartIcon />
        <div className={`${styles.numberOfItems} ${styles.badge}`}>
          {showCartCount()}
        </div>
      </div>
    );
  }
  return (
    <span className={styles.emptyCart}>
      <CartIcon />
    </span>
  );
};

interface CartLinkProps {
  className?: string;
}

const CartLink = ({ className }: CartLinkProps) => {
  const siteMap = useSiteMap();
  const href = siteMap.cart();
  const navigationTracking = () => {
    const ga4Event = navigationEvent({
      link_text: 'cart',
      link_url: href,
      ui_element_location: 'header',
      navigation_selection: 'cart',
    });
    ga4Track(ga4Event);
  };

  return (
    <Link
      href={href}
      data-nav="nav_Cart"
      data-testid="nav-cart-link"
      className={`${headerStyles.allianceOverrides} ${styles.link} ${className}`}
      onClick={navigationTracking}
    >
      <CartLinkContent />
    </Link>
  );
};

export default CartLink;
