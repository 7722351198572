import { useTranslations } from '@unisporkal/localization';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';

export const useEditorialUserGenerated = () => {
  const trackingId = 'Editorial_User_Generated';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();
  const editorialOverviewLinks = useEditorialOverviewLinks();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialUserGenerated,
    overviewText: {
      title: t('user_generated_with_hyphen'),
      body: t('discover_latest_user_generated_content'),
    },
    popularPills: {
      title: t('popular_user_generated_categories'),
      items: editorialPopularCategoryLinks.userGen,
    },
  };
};

export default useEditorialUserGenerated;
