import React, { useState } from 'react';
import { useTranslations } from '@unisporkal/localization';
import useExpertCurations from '../../../../../hooks/getty/megaNav/useExpertCurations';
import useMediaAndSportsCoverage from '../../../../../hooks/getty/megaNav/useMediaAndSports';
import usePartnerCollections from '../../../../../hooks/getty/megaNav/usePartnerCollections';
import { useNavLinks } from '../../../../../hooks/getty/useNavLinks';
import { NavMenuItem } from '../../../../../../types/link';
import MegaNav from '../../MegaNav/MegaNav';
import megaNavTracking from '../../Tracking/Tracking';
import MegaNavContent from '../../MegaNavContent/MegaNavContent';

interface CollectionsMegaNavProps {
  isOpen: boolean;
}

const CollectionsMegaNav = ({ isOpen }: CollectionsMegaNavProps) => {
  const t = useTranslations();
  const groupTitle = t('collections');
  const links = useNavLinks();
  const [selectedNavItemId, setSelectedNavItemId] = useState<string>(
    links.collections.id
  );

  const expertCurations = useExpertCurations();
  const mediaAndSports = useMediaAndSportsCoverage();
  const partnerCollections = usePartnerCollections();

  const trackOverviewLinkClick = (trackingId) => (event, link) =>
    megaNavTracking.trackOverviewNavigate(event, link, trackingId);

  const linkWithContent = (link, content) => ({
    ...link,
    content: (
      <MegaNavContent
        overviewLink={content.overviewLink}
        overviewText={content.overviewText}
        onOverviewLinkClick={trackOverviewLinkClick(content.trackingId)}
      />
    ),
  });

  const navItems: NavMenuItem[] = [
    linkWithContent(links.collectionsExpertCurations, expertCurations),
    linkWithContent(links.collectionsMediaAndSportsCoverage, mediaAndSports),
    linkWithContent(links.collectionsPartnerCollections, partnerCollections),
  ];

  return (
    <MegaNav
      isOpen={isOpen}
      groupTitle={groupTitle}
      navItems={navItems}
      selectedNavItemId={selectedNavItemId}
      onSelectNavItemId={setSelectedNavItemId}
      onNavigate={(event, link) =>
        megaNavTracking.trackNavMenuNavigate(event, link, 'collections')
      }
      simplifiedMegaNav
    />
  );
};

export default CollectionsMegaNav;
