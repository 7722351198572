import React from 'react';
import {
  NavMenuItem,
  NavLinkMenuItem,
  NavButtonMenuItem,
} from '../../../../../types/link';
import NavMenu from '../NavMenu/NavMenu';
import styles from './NavMenuDropdown.module.scss';

interface NavMenuDropdownProps {
  menuItems: NavMenuItem[];
  onNavigate: (
    evt: React.UIEvent<HTMLAnchorElement>,
    item: NavLinkMenuItem
  ) => void;
  onButtonClick?: (
    evt: React.UIEvent<HTMLButtonElement>,
    item: NavButtonMenuItem
  ) => void;
}

/*
 * List of buttons/links that can be navigated with keyboard arrows
 * Links/buttons are highlighted when hovered/focused
 */

const NavMenuDropdown = ({
  menuItems,
  onNavigate,
  onButtonClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
}: NavMenuDropdownProps) => {
  const handleClick = (evt: React.UIEvent<HTMLElement>, item: NavMenuItem) => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('href')) {
      onNavigate(
        evt as React.UIEvent<HTMLAnchorElement>,
        item as NavLinkMenuItem
      );
    } else {
      onButtonClick?.(
        evt as React.UIEvent<HTMLButtonElement>,
        item as NavButtonMenuItem
      );
    }
  };

  return (
    <nav className={styles.container}>
      <NavMenu
        menuItems={menuItems}
        onClick={handleClick}
      />
    </nav>
  );
};

export default NavMenuDropdown;
