import { useTranslations } from '@unisporkal/localization';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';

export const useEditorialImages = () => {
  const trackingId = 'Editorial_Images';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();

  // Trending searches links
  const {
    data: { editorialTrendingSearchLinksImage },
  } = useHeaderPropsQuery();

  // Use Popular Categories for non-en fallbacks
  // https://app.clickup.com/t/86az914g4
  const defaultPhotosTrendingSearches =
    editorialPopularCategoryLinks?.photos || [];

  const zeroTrendingSearchResults =
    editorialTrendingSearchLinksImage?.length === 0;

  const editorialImagesTrendingSearchLinks = zeroTrendingSearchResults
    ? defaultPhotosTrendingSearches.slice(0, 6)
    : editorialTrendingSearchLinksImage;

  const editorialOverviewLinks = useEditorialOverviewLinks();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialImages,
    overviewText: {
      title: t('editorial_images'),
      body: t('explore_latest_news_sports_entertainment_images'),
    },
    trendingPills: {
      title: t('trending_image_searches'),
      items: editorialImagesTrendingSearchLinks,
    },
    popularPills: {
      title: t('popular_image_categories'),
      items: editorialPopularCategoryLinks.photos,
    },
  };
};

export default useEditorialImages;
