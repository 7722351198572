import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import UnsplashLogo from '../../../header/components/getty/icons/UnsplashForBrands.svg';
import { useEnterpriseOverviewLinks } from '../useOverviewLinks';

export const useUnsplash = () => {
  const trackingId = 'Unsplash';
  const t = useTranslations();
  const EnterpriseOverviewLinks = useEnterpriseOverviewLinks();
  const unsplashLogo = () => (
    <img
      src={UnsplashLogo}
      alt="Unsplash for Brands"
    />
  );

  return {
    trackingId,
    overviewLink: EnterpriseOverviewLinks.unsplashSection,
    overviewText: {
      title: unsplashLogo(),
      body: t('grow_your_brand_authentically'),
    },
  };
};
export default useUnsplash;
