import {
  ga4Track,
  navigationEvent,
  loginInteraction,
  selectBoardEvent,
  viewAllBoardsEvent,
} from '@unisporkal/ga4';
import {
  USER_PANEL_VIEW,
  PANELTYPES,
  BOARD_PANEL_VIEW,
  PANEL_VIEW,
} from '../constants';

interface NavigationTrackingProps {
  name: string;
  path: string;
  location: string;
}

const getGA4PanelSelection = (view: PANEL_VIEW): string => {
  switch (view) {
    case USER_PANEL_VIEW.ACCOUNT:
      return 'account_panel';
    case USER_PANEL_VIEW.SIGNED_OUT:
      return 'sign_in_panel';
    case BOARD_PANEL_VIEW.CREATE_BOARD:
      return 'create_board_panel';
    case BOARD_PANEL_VIEW.RECENT_BOARDS:
      return 'recent_boards_panel';
    case PANELTYPES.BROWSE:
      return 'browse_panel';
    default:
      return '';
  }
};

export const trackNavigation = ({
  name,
  path,
  location,
}: NavigationTrackingProps): void => {
  const ga4Event = navigationEvent({
    link_text: name,
    link_url: path,
    ui_element_location: location,
    navigation_selection: name,
  });
  ga4Track(ga4Event);
};

export const trackSelectLogin = (uiLocation: string) => {
  const ga4Event = loginInteraction({
    event_name: 'select_login',
    ui_element_location: uiLocation,
  });
  ga4Track(ga4Event);
};

const togglePanelTrackingEvent = (
  isOpen: boolean,
  panelLocation: string,
  panelView: PANEL_VIEW,
  linkText: string
) => {
  const ga4Event = navigationEvent({
    event_name: isOpen ? 'open_panel' : 'close_panel',
    navigation_selection: getGA4PanelSelection(panelView),
    link_text: linkText,
    link_url: undefined,
    ui_element_location: `header_side_panel_${panelLocation}`,
  });

  ga4Track(ga4Event);
};

export const closePanelTrackingEvent = (
  panelLocation: string,
  panelView: PANEL_VIEW
) => {
  togglePanelTrackingEvent(false, panelLocation, panelView, 'close drawer');
};

export const openPanelTrackingEvent = (
  panelLocation: string,
  panelView: PANEL_VIEW,
  linkText: string
) => {
  togglePanelTrackingEvent(true, panelLocation, panelView, linkText);
};

export const boardSelectedTrackingEvent = (uiElementLocation) => {
  const ga4Event = selectBoardEvent({
    ui_element_location: uiElementLocation,
  });
  ga4Track(ga4Event);
};

export const viewAllBoardsTrackingEvent = (uiElementLocation) => {
  const ga4Event = viewAllBoardsEvent({
    ui_element_location: uiElementLocation,
  });
  ga4Track(ga4Event);
};
