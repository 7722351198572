import React, { useContext } from 'react';
import cx from 'classnames';
import OverlayContext from './OverlayContext';
import styles from './Overlay.module.scss';

const Overlay = () => {
  const { isOpen } = useContext(OverlayContext);

  return (
    <div
      data-testid="overlay"
      className={cx(styles.overlay, { [styles.show]: isOpen })}
    />
  );
};

export default Overlay;
