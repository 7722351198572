import React, { useState } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { useNavLinks } from '../../../../hooks/getty/useNavLinks';
import { useCustomContent } from '../../../../hooks/getty/megaNav/useCustomContent';
import { usePremiumAccess } from '../../../../hooks/getty/megaNav/usePremiumAccess';
import { useMediaManager } from '../../../../hooks/getty/megaNav/useMediaManager';
import { useUnsplash } from '../../../../hooks/getty/megaNav/useUnsplash';
import IconUnsplashLogo from '../icons/UnsplashForBrands.svg?icon';
import { NavMenuItem } from '../../../../../types/link';
import megaNavTracking from '../Tracking/Tracking';
import MegaNavContent from '../MegaNavContent/MegaNavContent';
import MegaNav from '../MegaNav/MegaNav';
import styles from './EnterpriseDropdown.module.scss';

interface EnterpriseMegaNavProps {
  isOpen: boolean;
}

const EnterpriseDropdown = ({ isOpen }: EnterpriseMegaNavProps) => {
  const t = useTranslations();
  const links = useNavLinks();
  const groupTitle = t('enterprise_header_nav');
  const [selectedNavItemId, setSelectedNavItemId] = useState<string>(
    links.enterprise.id
  );

  const premiumAccess = usePremiumAccess();
  const customContent = useCustomContent();
  const mediaManager = useMediaManager();
  const unsplash = useUnsplash();

  const trackOverviewLinkClick = (trackingId) => (event, link) =>
    megaNavTracking.trackOverviewNavigate(event, link, trackingId);

  const linkWithContent = (link, content) => ({
    ...link,
    content: (
      <MegaNavContent
        overviewLink={content.overviewLink}
        overviewText={content.overviewText}
        onOverviewLinkClick={trackOverviewLinkClick(content.trackingId)}
      />
    ),
  });

  const unsplashLogo = () => <IconUnsplashLogo className={styles.logo} />;

  const navItems: NavMenuItem[] = [
    linkWithContent(links.enterprisePremiumAccess, premiumAccess),
    linkWithContent(links.enterpriseCustomContent, customContent),
    linkWithContent(links.enterpriseMediaManager, mediaManager),
    linkWithContent(
      { ...links.unsplashForBrands, label: unsplashLogo() },
      unsplash
    ),
  ];
  return (
    <MegaNav
      isOpen={isOpen}
      groupTitle={groupTitle}
      navItems={navItems}
      selectedNavItemId={selectedNavItemId}
      onSelectNavItemId={setSelectedNavItemId}
      onNavigate={(event, link) =>
        megaNavTracking.trackNavMenuNavigate(
          event,
          link,
          'enterprise_header_nav'
        )
      }
      simplifiedMegaNav
    />
  );
};

export default EnterpriseDropdown;
