import React from 'react';
import { Link } from '@unisporkal/alliance-ui-link';
import { ListItem } from '@unisporkal/alliance-ui-list';
import { LinkItem } from '../../../../../types/link';
import styles from './MegaNavPill.module.scss';

export interface MegaNavPillProps {
  link: LinkItem;
  onClick: (evt: React.UIEvent<HTMLAnchorElement>, link: LinkItem) => void;
}

const MegaNavPill = ({ link, onClick }: MegaNavPillProps) => (
  <ListItem
    data-testid="mega-nav-pillcontainer"
    className={styles.pillContainer}
  >
    <Link
      data-ui-location="header_sub_nav"
      data-testid={`mega-nav-pill-${link.gaName}`}
      className={styles.pill}
      href={link.href}
      onClick={(evt) => onClick(evt, link)}
    >
      {link.name}
    </Link>
  </ListItem>
);

export default MegaNavPill;
