import { SearchPathBuilder } from '@unisporkal/search-url-builder';

interface PopularCategoryLinks {
  trackingLabel: string;
  localizedLinkText: string;
  path: string;
  sort: string;
}
export default class PopularCategories {
  editorial: {
    archives: PopularCategoryLinks[];
    entertainment: PopularCategoryLinks[];
    fashion: PopularCategoryLinks[];
    news: PopularCategoryLinks[];
    photos: PopularCategoryLinks[];
    sports: PopularCategoryLinks[];
    userGen: PopularCategoryLinks[];
    videos: PopularCategoryLinks[];
  };

  creative: {
    photos: PopularCategoryLinks[];
    videos: PopularCategoryLinks[];
    illustrations: PopularCategoryLinks[];
    vectors: PopularCategoryLinks[];
  };

  searchPathBuilder: SearchPathBuilder;

  constructor(
    creativePopularCategories,
    editorialPopularCategories,
    searchPathBuilder
  ) {
    this.editorial = {
      archives: editorialPopularCategories.archives,
      entertainment: editorialPopularCategories.entertainment,
      fashion: editorialPopularCategories.fashion,
      news: editorialPopularCategories.news,
      photos: editorialPopularCategories.photos,
      sports: editorialPopularCategories.sports,
      userGen: editorialPopularCategories.userGen,
      videos: editorialPopularCategories.videos,
    };

    this.creative = {
      photos: creativePopularCategories.photos,
      videos: creativePopularCategories.videos,
      illustrations: creativePopularCategories.illustrations,
      vectors: creativePopularCategories.vectors,
    };
    this.searchPathBuilder = searchPathBuilder;
  }

  creativePopularCategoryLinks = () => {
    const categoryToCreativePhotoLink =
      this.searchPathBuilder.getPopularCategoryLink();
    const categoryToCreativeVideoLink =
      this.searchPathBuilder.getPopularCategoryLink();
    const categoryToCreativeIllustrationLink =
      this.searchPathBuilder.getPopularCategoryLink();
    const categoryToCreativeVectorLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=creative&assetfiletype=eps'
      );

    return {
      photos: this.creative.photos.map(categoryToCreativePhotoLink),
      videos: this.creative.videos.map(categoryToCreativeVideoLink),
      illustrations: this.creative.illustrations.map(
        categoryToCreativeIllustrationLink
      ),
      vectors: this.creative.vectors.map(categoryToCreativeVectorLink),
    };
  };

  editorialPopularCategoryLinks = () => {
    const categoryToEditorialVideoLink =
      this.searchPathBuilder.getPopularCategoryLink();
    const categoryToEditorialUserGenLink =
      this.searchPathBuilder.getPopularUserGeneratedLink();
    const categoryToEditorialPhotoLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=editorial&editorialproducts=all'
      );
    const categoryToEditorialFashionLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=editorial&editorialproducts=all'
      );
    const categoryToEditorialNewsLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=editorial&editorialproducts=news'
      );
    const categoryToEditorialSportsLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=editorial&editorialproducts=sport'
      );
    const categoryToEditorialEntertainmentLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=editorial&editorialproducts=entertainment'
      );
    const categoryToEditorialArchivesLink =
      this.searchPathBuilder.getPopularCategoryLink(
        '?family=editorial&editorialproducts=archival'
      );

    return {
      photos: this.editorial.photos.map(categoryToEditorialPhotoLink),
      videos: this.editorial.videos.map(categoryToEditorialVideoLink),
      news: this.editorial.news.map(categoryToEditorialNewsLink),
      sports: this.editorial.sports.map(categoryToEditorialSportsLink),
      entertainment: this.editorial.entertainment.map(
        categoryToEditorialEntertainmentLink
      ),
      fashion: this.editorial.fashion.map(categoryToEditorialFashionLink),
      userGen: this.editorial.userGen.map(categoryToEditorialUserGenLink),
      archives: this.editorial.archives.map(categoryToEditorialArchivesLink),
    };
  };
}
