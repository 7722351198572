import { useTranslations } from '@unisporkal/localization';
import { useNavLinks } from './useNavLinks';

export interface OverviewLink {
  href: string;
  name: string;
  gaName: string;
}

export const useCreativeOverviewLinks = () => {
  const t = useTranslations();
  const links = useNavLinks();

  const creativeImages: OverviewLink = {
    href: links.creativeImages.href,
    name: t('see_all_creative_images'),
    gaName: 'see_all_creative_images',
  };

  const topCreativeImageSearches: OverviewLink = {
    href: links.topCreativeImageSearches.href,
    name: t('top_image_searches_lower'),
    gaName: 'top_image_searches_lower',
  };

  const topCreativeVideoSearches: OverviewLink = {
    href: links.topCreativeVideoSearches.href,
    name: t('top_video_searches_lower'),
    gaName: 'top_video_searches_lower',
  };

  const creativeVideos: OverviewLink = {
    href: links.creativeVideos.href,
    name: t('see_all_creative_videos'),
    gaName: 'see_all_creative_videos',
  };

  const creativeIllustrations: OverviewLink = {
    href: links.creativeIllustrations.href,
    name: t('see_all_illustrations'),
    gaName: 'see_all_illustrations',
  };

  const creativeVectors: OverviewLink = {
    href: links.creativeVectors.href,
    name: t('see_all_vectors'),
    gaName: 'see_all_vectors',
  };

  const creativeMusic: OverviewLink = {
    href: links.creativeMusic.href,
    name: t('see_all_music'),
    gaName: 'discover_music',
  };

  return {
    creativeImages,
    topCreativeImageSearches,
    topCreativeVideoSearches,
    creativeVideos,
    creativeIllustrations,
    creativeVectors,
    creativeMusic,
  };
};

export const useEditorialOverviewLinks = () => {
  const t = useTranslations();
  const links = useNavLinks();

  const editorialImages: OverviewLink = {
    href: links.editorialImages.href,
    name: t('see_all_editorial_images'),
    gaName: 'see_all_editorial_images',
  };

  const editorialVideos: OverviewLink = {
    href: links.editorialVideos.href,
    name: t('see_all_editorial_videos'),
    gaName: 'see_all_editorial_videos',
  };

  const editorialNews: OverviewLink = {
    href: links.editorialNews.href,
    name: t('explore_latest_news_visuals'),
    gaName: 'explore_latest_news_visuals',
  };

  const editorialSports: OverviewLink = {
    href: links.editorialSports.href,
    name: t('explore_latest_sports_visuals'),
    gaName: 'explore_latest_sports_visuals',
  };

  const editorialEntertainment: OverviewLink = {
    href: links.editorialEntertainment.href,
    name: t('explore_entertainment_visuals'),
    gaName: 'explore_entertainment_visuals',
  };

  const editorialFashion: OverviewLink = {
    href: links.editorialFashion.href,
    name: t('explore_fashion_visuals'),
    gaName: 'explore_fashion_visuals',
  };

  const editorialUserGenerated: OverviewLink = {
    href: links.editorialUserGenerated.href,
    name: t('explore_user_generated_visuals'),
    gaName: 'explore_user_generated_visuals',
  };

  const editorialArchive: OverviewLink = {
    href: links.editorialArchive.href,
    name: t('explore_our_archives'),
    gaName: 'explore_our_archives',
  };

  return {
    editorialImages,
    editorialVideos,
    editorialNews,
    editorialSports,
    editorialEntertainment,
    editorialFashion,
    editorialUserGenerated,
    editorialArchive,
  };
};

export const useCollectionsOverviewLinks = () => {
  const t = useTranslations();
  const links = useNavLinks();

  const expertCurations: OverviewLink = {
    href: links.collectionsExpertCurations.href,
    name: t('find_premium_visuals'),
    gaName: 'find_premium_visuals',
  };

  const mediaAndSportsCoverage: OverviewLink = {
    href: links.collectionsMediaAndSportsCoverage.href,
    name: t('access_iconic_moments'),
    gaName: 'access_iconic_moments',
  };

  const partnerCollections: OverviewLink = {
    href: links.collectionsPartnerCollections.href,
    name: t('uncover_authentic_images_and_videos'),
    gaName: 'uncover_authentic_images_and_videos',
  };

  return {
    expertCurations,
    mediaAndSportsCoverage,
    partnerCollections,
  };
};

export const useEnterpriseOverviewLinks = () => {
  const t = useTranslations();
  const links = useNavLinks();

  const premiumAccess: OverviewLink = {
    href: links.enterprisePremiumAccess.href,
    name: t('discover_premium_access'),
    gaName: 'premium_access',
  };

  const customContent: OverviewLink = {
    href: links.enterpriseCustomContent.href,
    name: t('explore_custom_content'),
    gaName: 'enterprise_custom_content',
  };

  const mediaManager: OverviewLink = {
    href: links.enterpriseMediaManager.href,
    name: t('check_out_media_manager'),
    gaName: 'enterprise_media_manager',
  };

  const unsplashSection: OverviewLink = {
    href: links.unsplashForBrands.href,
    name: t('learn_more_string'),
    gaName: 'enterprise_unsplash_for_brands',
  };

  return {
    premiumAccess,
    customContent,
    mediaManager,
    unsplashSection,
  };
};
