import React, { useState, useMemo } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { useNavLinks } from '../../../../../hooks/getty/useNavLinks';
import useHeaderPropsQuery from '../../../../../hooks/queries/useHeaderPropsQuery';
import useAIGenerator from '../../../../../hooks/getty/megaNav/useAIGenerator';
import megaNavTracking from '../../Tracking/Tracking';
import { NavMenuItem } from '../../../../../../types/link';
import MegaNav from '../../MegaNav/MegaNav';
import MegaNavContent from '../../MegaNavContent/MegaNavContent';
import StartGeneratingButton from './StartGeneratingButton';

interface AIGeneratorMegaNavProps {
  isOpen: boolean;
}

const AIGeneratorMegaNav = ({ isOpen }: AIGeneratorMegaNavProps) => {
  const t = useTranslations();
  const {
    data: { hasActiveGenerationAgreement },
  } = useHeaderPropsQuery();
  const groupTitle = t('ai_generator_title_casing');
  const links = useNavLinks();
  const [selectedNavItemId, setSelectedNavItemId] = useState<string>(
    links.aiGenerator.id
  );
  const { pricing, generate, modify, custom } = useAIGenerator();
  const trackLinkClick = (trackingId) => (event, link) =>
    megaNavTracking.trackOverviewNavigate(event, link, trackingId);

  const linkWithContent = (link, content) => ({
    ...link,
    content: (
      <MegaNavContent
        overviewLink={content.overviewLink}
        topSearchesLink={content.topSearchesLink}
        secondaryLinks={content.secondaryLinks}
        overviewText={content.overviewText}
        onOverviewLinkClick={trackLinkClick(content.trackingId)}
        onSecondaryLinkClick={trackLinkClick(content.trackingId)}
      />
    ),
  });

  const navItems: NavMenuItem[] = useMemo(
    () => [
      linkWithContent(links.aiGeneratorPricing, pricing),
      linkWithContent(links.aiGenerateNewImages, generate),
      linkWithContent(links.aiGeneratorModify, modify),
      linkWithContent(links.aiGeneratorCustom, custom),
    ],
    []
  );

  const handleTrackLinkClick = (event, link) =>
    megaNavTracking.trackNavMenuNavigate(event, link, 'ai_generator');

  return (
    <MegaNav
      isOpen={isOpen}
      groupTitle={groupTitle}
      navItems={navItems}
      selectedNavItemId={selectedNavItemId}
      onSelectNavItemId={setSelectedNavItemId}
      onNavigate={handleTrackLinkClick}
      ctaButton={
        hasActiveGenerationAgreement ? (
          <StartGeneratingButton onButtonClick={handleTrackLinkClick} />
        ) : null
      }
    />
  );
};

export default AIGeneratorMegaNav;
