import { useTranslations } from '@unisporkal/localization';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import usePopularCategories from '../usePopularCategories';

export const useEditorialSports = () => {
  const trackingId = 'Editorial_Sports';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();
  const editorialOverviewLinks = useEditorialOverviewLinks();
  const {
    data: { editorialTrendingSearchLinksSports },
  } = useHeaderPropsQuery();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialSports,
    overviewText: {
      title: t('editorialproducts_sport'),
      body: t('from_sidelines_to_pitch'),
    },
    trendingPills: {
      title: t('trending_sports_searches'),
      items: editorialTrendingSearchLinksSports,
    },
    popularPills: {
      title: t('popular_sports_categories'),
      items: editorialPopularCategoryLinks.sports,
    },
  };
};

export default useEditorialSports;
