import React, { useContext, useState } from 'react';
import cx from 'classnames';
import { ListItem } from '@unisporkal/alliance-ui-list';
import OverlayContext from '../Overlay/OverlayContext';
import styles from './NavBarListItem.module.scss';

interface NavBarListItemProps {
  children:
    | React.ReactNode
    | ((
        isDropdownOpen: boolean,
        toggleDropdown: (isExpanded: boolean) => void
      ) => [React.ReactNode, React.ReactNode]); // [navlink/navbutton, dropdown]
  className?: string;
  dataTestId?: string;
  hasFullWidthDropdown?: boolean;
}

const NavBarListItem = ({
  children,
  className = '',
  dataTestId = '',
  hasFullWidthDropdown = false,
}: NavBarListItemProps) => {
  const { setIsOpen: setIsOverlayOpen } = useContext(OverlayContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const classes = cx(styles.item, {
    [className]: !!className,
    [styles.hasFullWidthDropdown]: hasFullWidthDropdown,
  });
  const [content, dropdown = null] =
    typeof children === 'function'
      ? children(isDropdownOpen, setIsDropdownOpen)
      : [children, null];

  let hoverTimeoutId;
  const delayedSetIsDropdownOpen = (isOpen) => {
    clearTimeout(hoverTimeoutId);
    hoverTimeoutId = setTimeout(() => {
      setIsDropdownOpen(isOpen);
      setIsOverlayOpen(isOpen);
    }, 300);
  };

  return (
    <ListItem
      /* eslint-disable react/jsx-props-no-spreading */
      {...(!!dataTestId && { 'data-testid': dataTestId })}
      {...(!!dropdown && {
        onMouseEnter: () => delayedSetIsDropdownOpen(true),
        onMouseLeave: () => delayedSetIsDropdownOpen(false),
      })}
      /* eslint-enable react/jsx-props-no-spreading */
      className={classes}
    >
      <div className={styles.childrenWrapper}>{content}</div>
      {!!dropdown && (
        <div
          data-testid="nav-dropdown"
          className={cx(styles.dropdown, { [styles.hidden]: !isDropdownOpen })}
        >
          {dropdown}
        </div>
      )}
    </ListItem>
  );
};

export default NavBarListItem;
