import { useTranslations } from '@unisporkal/localization';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';

export const useEditorialVideos = () => {
  const trackingId = 'Editorial_Videos';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();

  // Trending searches links
  const {
    data: { editorialTrendingSearchLinksVideo },
  } = useHeaderPropsQuery();

  // Use Popular Categories for non-en fallbacks
  // https://app.clickup.com/t/86az914g4
  const defaultVideoTrendingSearches =
    editorialPopularCategoryLinks?.videos || [];

  const zeroTrendingSearchResults =
    editorialTrendingSearchLinksVideo?.length === 0;

  const editorialVideosTrendingSearchLinks = zeroTrendingSearchResults
    ? defaultVideoTrendingSearches.slice(0, 6)
    : editorialTrendingSearchLinksVideo;

  const editorialOverviewLinks = useEditorialOverviewLinks();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialVideos,
    overviewText: {
      title: t('editorial_videos'),
      body: t('explore_latest_news_sports_entertainment_videos'),
    },
    trendingPills: {
      title: t('trending_video_searches'),
      items: editorialVideosTrendingSearchLinks,
    },
    popularPills: {
      title: t('popular_video_categories'),
      items: editorialPopularCategoryLinks.videos,
    },
  };
};

export default useEditorialVideos;
