import React, { useMemo, useState, UIEvent } from 'react';
import { useTranslations } from '@unisporkal/localization';
import { NavLinkItem, NavMenuItem } from '../../../../../../types/link';
import { PILLTYPES } from '../../../../../constants';
import useCreativeImages from '../../../../../hooks/getty/megaNav/useCreativeImages';
import useCreativeVideos from '../../../../../hooks/getty/megaNav/useCreativeVideos';
import useCreativeIllustrations from '../../../../../hooks/getty/megaNav/useCreativeIllustrations';
import useCreativeVectors from '../../../../../hooks/getty/megaNav/useCreativeVectors';
import useEditorialImages from '../../../../../hooks/getty/megaNav/useEditorialImages';
import useEditorialVideos from '../../../../../hooks/getty/megaNav/useEditorialVideos';
import useEditorialNews from '../../../../../hooks/getty/megaNav/useEditorialNews';
import useEditorialSports from '../../../../../hooks/getty/megaNav/useEditorialSports';
import useEditorialEntertainment from '../../../../../hooks/getty/megaNav/useEditorialEntertainment';
import useEditorialFashion from '../../../../../hooks/getty/megaNav/useEditorialFashion';
import useEditorialUserGenerated from '../../../../../hooks/getty/megaNav/useEditorialUserGenerated';
import useEditorialArchive from '../../../../../hooks/getty/megaNav/useEditorialArchive';
import useExpertCurations from '../../../../../hooks/getty/megaNav/useExpertCurations';
import useMediaAndSportsCoverage from '../../../../../hooks/getty/megaNav/useMediaAndSports';
import usePartnerCollections from '../../../../../hooks/getty/megaNav/usePartnerCollections';
import useAIGenerator from '../../../../../hooks/getty/megaNav/useAIGenerator';
import { useNavLinks } from '../../../../../hooks/getty/useNavLinks';
import SidebarMegaNav from '../../SidebarMegaNav/SidebarMegaNav';
import MobileMegaNavContent from '../../MobileMegaNavContent/MobileMegaNavContent';
import useHeaderPropsQuery from '../../../../../hooks/queries/useHeaderPropsQuery';
import UnsplashLogo from '../../icons/UnsplashForBrands.svg';
import styles from './MobileMegaNav.module.scss';
import megaNavTracking from '../../Tracking/Tracking';

const MobileMegaNav = () => {
  const [panelPath, setPanelPath] = useState<string[]>([]);
  const links = useNavLinks();
  const creativeImage = useCreativeImages();
  const creativeVideo = useCreativeVideos();
  const creativeIllustration = useCreativeIllustrations();
  const creativeVector = useCreativeVectors();
  const editorialImage = useEditorialImages();
  const editorialVideo = useEditorialVideos();
  const editorialNews = useEditorialNews();
  const editorialSports = useEditorialSports();
  const editorialEntertainment = useEditorialEntertainment();
  const editorialFashion = useEditorialFashion();
  const editorialUserGen = useEditorialUserGenerated();
  const editorialArchive = useEditorialArchive();
  const expertCurations = useExpertCurations();
  const mediaAndSportsCoverage = useMediaAndSportsCoverage();
  const partnerCollections = usePartnerCollections();
  const { pricing, generate, modify, custom } = useAIGenerator();
  const currentPanelId = panelPath[panelPath.length - 1];
  const isViewAllCuratedPills = currentPanelId === PILLTYPES.CURATED;
  const isViewAllPopularPills = currentPanelId === PILLTYPES.POPULAR;
  const isViewAllTrendingPills = currentPanelId === PILLTYPES.TRENDING;
  const t = useTranslations();
  const {
    data: { showAiGenPreShotMerchandising },
  } = useHeaderPropsQuery();

  const handleViewAllPillsClick =
    (trackingId: string) => (pillType: string) => {
      // Ignore multiple clicks on view all button
      if (pillType === currentPanelId) {
        return;
      }

      setPanelPath([...panelPath, pillType]); // Show pills panel
      megaNavTracking.trackViewAllPillsNavigate(pillType, trackingId);
    };

  const trackPillClick = (trackingId) => (event, link, section) =>
    megaNavTracking.trackPillNavigate(event, link, section, trackingId);
  const trackOverviewLinkClick = (trackingId) => (event, link) =>
    megaNavTracking.trackOverviewNavigate(event, link, trackingId);

  const linkWithContent = (link, content) => ({
    ...link,
    content: (
      <MobileMegaNavContent
        overviewLink={content.overviewLink}
        overviewText={content.overviewText}
        topSearchesLink={content.topSearchesLink}
        curatedPills={content.curatedPills}
        trendingPills={content.trendingPills}
        popularPills={content.popularPills}
        secondaryLinks={content.secondaryLinks}
        isViewAllCuratedPills={isViewAllCuratedPills}
        isViewAllPopularPills={isViewAllPopularPills}
        isViewAllTrendingPills={isViewAllTrendingPills}
        onPillClick={trackPillClick(content.trackingId)}
        onOverviewLinkClick={trackOverviewLinkClick(content.trackingId)}
        onTopSearchesLinkClick={trackOverviewLinkClick(content.trackingId)}
        onSecondaryLinkClick={trackOverviewLinkClick(content.trackingId)}
        onViewAllPillsClick={handleViewAllPillsClick(content.trackingId)}
      />
    ),
  });

  const navItems = useMemo(() => {
    const creativeNavItem = {
      ...links.creative,
      childNavMenu: [
        linkWithContent(links.creativeImages, creativeImage),
        linkWithContent(links.creativeVideos, creativeVideo),
        linkWithContent(links.creativeIllustrations, creativeIllustration),
        linkWithContent(links.creativeVectors, creativeVector),
        links.creativeMusic,
      ],
    };
    const editorialNavItem = {
      ...links.editorial,
      childNavMenu: [
        linkWithContent(links.editorialImages, editorialImage),
        linkWithContent(links.editorialVideos, editorialVideo),
        linkWithContent(links.editorialNews, editorialNews),
        linkWithContent(links.editorialSports, editorialSports),
        linkWithContent(links.editorialEntertainment, editorialEntertainment),
        linkWithContent(links.editorialFashion, editorialFashion),
        linkWithContent(links.editorialUserGenerated, editorialUserGen),
        linkWithContent(links.editorialArchive, editorialArchive),
      ],
    };

    const collectionsNavItem = {
      ...links.collections,
      childNavMenu: [
        linkWithContent(links.collectionsExpertCurations, expertCurations),
        linkWithContent(
          links.collectionsMediaAndSportsCoverage,
          mediaAndSportsCoverage
        ),
        linkWithContent(
          links.collectionsPartnerCollections,
          partnerCollections
        ),
      ],
    };

    const getAiGenNavItems = () => {
      if (showAiGenPreShotMerchandising) {
        return {
          ...links.aiGenerator,
          childNavMenu: [
            linkWithContent(links.aiGeneratorPricing, pricing),
            linkWithContent(links.aiGeneratorGenerate, generate),
            linkWithContent(links.aiGeneratorModify, modify),
            linkWithContent(links.aiGeneratorCustom, custom),
          ],
        };
      }
      return {
        ...links.aiGenerator,
      };
    };

    const unsplashLogo = () => (
      <img
        src={UnsplashLogo}
        alt="Unsplash for Brands"
      />
    );
    const enterpriseNavItem = {
      ...links.enterprise,
      childNavMenu: [
        { ...links.enterprise, name: t('enterprise_solutions') },
        links.enterprisePremiumAccess,
        links.enterpriseCustomContent,
        links.enterpriseMediaManager,
        { ...links.unsplashForBrands, label: unsplashLogo() },
      ],
    };

    return [
      creativeNavItem,
      editorialNavItem,
      {
        ...links.video,
        childNavMenu: [links.creativeVideo, links.editorialVideo],
      },
      collectionsNavItem,
      {
        ...links.visualGps,
      },
      getAiGenNavItems(),
      enterpriseNavItem,
      {
        ...links.pricing,
        className: styles.isMobileOnly,
      },
    ];
  }, [panelPath]);

  const trackNavMenuNavigate = (
    evt: UIEvent<HTMLElement>,
    link: NavLinkItem,
    ga4NavigationSelection: string,
    parentItem: Nullable<NavMenuItem> = null
  ) => {
    // Mobile nav links should have same uiElementLocation as desktop
    const uiElementLocation = !parentItem ? 'header' : 'header_sub_nav';

    megaNavTracking.trackNavMenuNavigate(
      evt,
      link,
      ga4NavigationSelection,
      uiElementLocation
    );
  };

  return (
    <SidebarMegaNav
      navItems={navItems}
      panelPath={panelPath}
      setPanelPath={setPanelPath}
      onNavigate={trackNavMenuNavigate}
    />
  );
};

export default MobileMegaNav;
