import React, { Suspense, useState } from 'react';
import { Drawer, DrawerAnchorEnum } from '@unisporkal/alliance-ui-drawer';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import useAccountPanelType from '../../../../hooks/useAccountPanelType';
import {
  USER_PANEL_VIEW,
  BOARD_PANEL_VIEW,
  PANELTYPES,
  PANELTYPES_RIGHT,
} from '../../../../constants';
import AccountPanel from '../AccountPanel/AccountPanel';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';

const BoardsPanel = lazyComponent(() => import('../BoardsPanel/BoardsPanel'));

interface RightPanelProps {
  className: string;
  panelSlideMs: number;
  panelComponentType: Nullable<PANELTYPES_RIGHT>;
  onPanelNavigate: (panelType: PANELTYPES_RIGHT) => void;
  onClose: (panelType: BOARD_PANEL_VIEW | USER_PANEL_VIEW) => void;
}

const RightPanel = ({
  className,
  panelSlideMs,
  panelComponentType,
  onPanelNavigate,
  onClose,
}: RightPanelProps) => {
  const accountPanelType = useAccountPanelType();
  const [boardPanelView, setBoardPanelView] =
    useState<Nullable<BOARD_PANEL_VIEW>>(null);

  const handleClose = () => {
    const panelType =
      panelComponentType === PANELTYPES.BOARDS
        ? (boardPanelView as BOARD_PANEL_VIEW)
        : (accountPanelType as USER_PANEL_VIEW);

    onClose(panelType);
  };

  return (
    <Drawer
      anchor={DrawerAnchorEnum.RIGHT}
      open={!!panelComponentType}
      onClose={handleClose}
      className={className}
      data-testid="RightPanelDrawer"
      transitionDuration={panelSlideMs}
    >
      {panelComponentType === PANELTYPES.BOARDS && (
        <Suspense fallback={<LoadingAnimation />}>
          <BoardsPanel
            panelView={boardPanelView}
            onPanelViewChange={setBoardPanelView}
          />
        </Suspense>
      )}
      {panelComponentType === PANELTYPES.USER && (
        <AccountPanel
          openBoardsPanel={() => onPanelNavigate(PANELTYPES.BOARDS)}
        />
      )}
    </Drawer>
  );
};

export default RightPanel;
