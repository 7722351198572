import { useEffect } from 'react';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { QUERY_TYPE } from '../../actions/types';
import useServiceClient from '../useServiceClient';

const useCartCount = () => {
  const { cartCountService } = useServiceClient();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (window.eventBus) {
      window.eventBus.on('addToFancyCart', () =>
        queryClient.invalidateQueries({ queryKey: QUERY_TYPE.CART_COUNT })
      );
      window.eventBus.on('removeFromFancyCart', () =>
        queryClient.invalidateQueries({ queryKey: QUERY_TYPE.CART_COUNT })
      );
    }
  }, []);

  return useSuspenseQuery({
    queryKey: QUERY_TYPE.CART_COUNT,
    queryFn: () => cartCountService.getCartCount(),
  });
};

export default useCartCount;
