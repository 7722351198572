import { useMemo } from 'react';
import { useSearchPathBuilder } from '@unisporkal/search-url-builder';
import useHeaderPropsQuery from '../queries/useHeaderPropsQuery';
import PopularCategories from '../../header/components/getty/models/PopularCategories';

const usePopularCategories = () => {
  const {
    data: { gettyCreativePopularCategories, gettyEditorialPopularCategories },
  } = useHeaderPropsQuery();

  const searchPathBuilder = useSearchPathBuilder();

  return useMemo(
    () =>
      new PopularCategories(
        gettyCreativePopularCategories,
        gettyEditorialPopularCategories,
        searchPathBuilder
      ),
    [gettyCreativePopularCategories, gettyEditorialPopularCategories]
  );
};
export default usePopularCategories;
