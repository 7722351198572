import { useTranslations } from '@unisporkal/localization';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import { useCreativeOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';

export const useCreativeVectors = () => {
  const trackingId = 'Creative_Vectors';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const creativePopularCategoryLinks =
    popularCategories.creativePopularCategoryLinks();
  const {
    data: { creativeTrendingSearchLinksVector },
  } = useHeaderPropsQuery();

  const creativeOverviewLinks = useCreativeOverviewLinks();

  return {
    trackingId,
    overviewLink: creativeOverviewLinks.creativeVectors,
    overviewText: {
      title: t('vectors'),
      body: t('explore_millions_of_royalty_free_vectors'),
    },
    trendingPills: {
      title: t('trending_vector_searches'),
      items: creativeTrendingSearchLinksVector,
    },
    popularPills: {
      title: t('popular_vector_categories'),
      items: creativePopularCategoryLinks.vectors,
    },
  };
};

export default useCreativeVectors;
