import React from 'react';
import { Flex } from '@unisporkal/alliance-ui-flex-box';
import { PANELTYPES, PANELTYPES_RIGHT } from '../../../../constants';
import { useNavLinks } from '../../../../hooks/getty/useNavLinks';
import Boards from '../Boards/Boards';
import Account from '../Account/Account';
import AIGeneratorLink from '../AIGeneratorLink/AIGeneratorLink';
import CartLink from '../CartLink/CartLink';
import { HamburgerButton } from '../Hamburger/Hamburger';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import NavBar, { NavBarVariantEnum } from '../NavBar/NavBar';
import NavLink, { NavLinkVariantEnum } from '../NavLink/NavLink';
import NavBarListItem from '../NavBarListItem/NavBarListItem';
import styles from './MainNavTop.module.scss';

interface MainNavTopProps {
  isVisualGps: boolean;
  isLeftPanelOpen: boolean;
  onOpenLeftPanel: () => void;
  onOpenRightPanel: (panelType: PANELTYPES_RIGHT) => void;
}

const MainNavTop = ({
  isVisualGps,
  isLeftPanelOpen,
  onOpenLeftPanel,
  onOpenRightPanel,
}: MainNavTopProps) => {
  const links = useNavLinks();

  return (
    <NavBar
      data-testid="nav-primary"
      variant={NavBarVariantEnum.PRIMARY}
    >
      <Flex
        as="ul"
        data-testid="primary-nav-left"
      >
        <NavBarListItem
          className={styles.hamburgerContainer}
          dataTestId="hamburger"
        >
          <HamburgerButton
            isPanelOpen={isLeftPanelOpen}
            onOpenPanel={onOpenLeftPanel}
          />
        </NavBarListItem>
        <NavBarListItem
          className={styles.logoContainer}
          dataTestId="header-logo"
        >
          <HeaderLogo />
        </NavBarListItem>
      </Flex>

      <Flex
        as="ul"
        data-testid="primary-nav-right"
      >
        <NavBarListItem
          className={styles.tablet}
          dataTestId="pricing"
        >
          <NavLink
            variant={NavLinkVariantEnum.LINK}
            link={links.pricing}
          />
        </NavBarListItem>

        {!isVisualGps ? (
          <NavBarListItem
            className={styles.largeUp}
            dataTestId="Boards"
          >
            <Boards
              className={styles.boardsButton}
              openBoardsPanel={onOpenRightPanel}
            />
          </NavBarListItem>
        ) : null}

        <NavBarListItem
          className={styles.mobileAndTablet}
          dataTestId="AIGenerator"
        >
          <AIGeneratorLink className={styles.aiLink} />
        </NavBarListItem>
        <NavBarListItem
          className={styles.mobileAndTablet}
          dataTestId="Cart"
        >
          <CartLink className={styles.cartLink} />
        </NavBarListItem>
        <NavBarListItem
          className={styles.accountButtonContainer}
          dataTestId="SignIn"
        >
          <Account
            openAccountPanel={() => onOpenRightPanel(PANELTYPES.USER)}
            visualGpsHeader={isVisualGps}
          />
        </NavBarListItem>
      </Flex>
    </NavBar>
  );
};

export default MainNavTop;
