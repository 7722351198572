import { useTranslations } from '@unisporkal/localization';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';
import usePopularCategories from '../usePopularCategories';

export const useEditorialNews = () => {
  const trackingId = 'Editorial_News';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();
  const editorialOverviewLinks = useEditorialOverviewLinks();
  const {
    data: { editorialTrendingSearchLinksNews },
  } = useHeaderPropsQuery();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialNews,
    overviewText: {
      title: t('news'),
      body: t('search_latest_news'),
    },
    trendingPills: {
      title: t('trending_news_searches'),
      items: editorialTrendingSearchLinksNews,
    },
    popularPills: {
      title: t('popular_news_categories'),
      items: editorialPopularCategoryLinks.news,
    },
  };
};

export default useEditorialNews;
