import { useMemo } from 'react';
import { useSearchPathBuilder } from '@unisporkal/search-url-builder';
import { useSiteMap } from '@unisporkal/sites';
import CuratedContent from '../../../header/components/getty/models/CuratedContent';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';

const useCuratedContent = () => {
  const {
    data: { seasonalContent },
  } = useHeaderPropsQuery();
  const siteMap = useSiteMap();
  const searchPathBuilder = useSearchPathBuilder();

  return useMemo(
    () => new CuratedContent(siteMap, searchPathBuilder, seasonalContent),
    []
  );
};

export default useCuratedContent;
