import React from 'react';

import PropTypes from 'prop-types';
import NotificationsList from '../shared/NotificationsList/NotificationsList';
import MinimalHeader from './MinimalHeader/MinimalHeader.tsx';
import VisualGpsHeader from './VisualGpsHeader/VisualGpsHeader.tsx';
import FullHeader from './FullHeader/FullHeader.tsx';
import styles from './Header.module.scss';

const Header = ({ layout, currentPage, showEnterpriseDropdown }) => {
  const headerLayout = () => {
    switch (layout) {
      case 'minimal':
      case 'pop_up':
        return <MinimalHeader layout={layout} />;
      case 'visual_gps':
        return <VisualGpsHeader />;
      default:
        return <FullHeader showEnterpriseDropdown={showEnterpriseDropdown} />;
    }
  };

  return (
    <div className={styles.container}>
      <NotificationsList currentPage={currentPage} />
      {headerLayout()}
    </div>
  );
};

export default Header;
Header.propTypes = {
  currentPage: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
  showEnterpriseDropdown: PropTypes.bool,
};

Header.defaultProps = {
  showEnterpriseDropdown: true,
};
