import React, { useEffect } from 'react';
import cx from 'classnames';
import { NavMenuItem, NavLinkMenuItem } from '../../../../../types/link';
import NavMenu from '../NavMenu/NavMenu';
import styles from './MegaNav.module.scss';

interface MegaNavProps {
  isOpen: boolean;
  groupTitle: string;
  navItems: NavMenuItem[];
  simplifiedMegaNav?: boolean;
  selectedNavItemId: string;
  onSelectNavItemId: (id: string) => void;
  onNavigate: (
    evt: React.UIEvent<HTMLAnchorElement>,
    item: NavLinkMenuItem
  ) => void;
  ctaButton?: React.ReactNode;
}

const MegaNav = ({
  isOpen,
  groupTitle,
  navItems,
  selectedNavItemId,
  simplifiedMegaNav = false,
  onSelectNavItemId,
  onNavigate = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  ctaButton,
}: MegaNavProps) => {
  // Reset selected nav item to first item on mega nav close
  useEffect(() => {
    if (!isOpen) {
      onSelectNavItemId(navItems[0].id);
    }
  }, [isOpen]);

  const handleSelectNavItemId = (id: Nullable<string>) => {
    if (!id) {
      return; // Always have a nav item selected
    }

    onSelectNavItemId(id);
  };

  const handleClick = (evt: React.UIEvent<HTMLElement>, item: NavMenuItem) => {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('href')) {
      onNavigate(
        evt as React.UIEvent<HTMLAnchorElement>,
        item as NavLinkMenuItem
      );
    }
  };

  return (
    <nav
      data-testid="mega-nav"
      className={styles.container}
    >
      <h5 className={styles.title}>{groupTitle}</h5>
      <NavMenu
        menuItems={navItems}
        selectedMenuItemId={selectedNavItemId}
        onSelectMenuItemId={handleSelectNavItemId}
        onClick={handleClick}
        simplifiedMegaNav={simplifiedMegaNav}
      >
        {(item: NavMenuItem, isSelected: boolean) =>
          !!item.content && (
            <div
              data-testid="mega-nav-content"
              className={cx(styles.content, { [styles.hidden]: !isSelected })}
            >
              {item.content}
            </div>
          )
        }
      </NavMenu>
      {ctaButton && <div className={styles.ctaButton}>{ctaButton}</div>}
    </nav>
  );
};

export default MegaNav;
