import cx from 'classnames';
import React, { ForwardedRef, forwardRef } from 'react';
import {
  hasChildNavMenu,
  NavButtonMenuItem,
  NavLinkMenuItem,
  NavMenuItem,
} from '../../../../../../types/link';
import { KeyboardEventCode } from '../../../../../utils/uiEvent';
import NavMenuOption from '../../NavMenu/NavMenuOption/NavMenuOption';
import styles from '../SidebarMegaNav.module.scss';

interface SidebarNavItemProps {
  item: hasChildNavMenu<NavMenuItem>;
  isSelected: boolean;
  onSelectItemId: (id: string) => void;
  onNavigate: (
    evt: React.UIEvent<HTMLAnchorElement>,
    item: NavLinkMenuItem
  ) => void;
  onKeyboardArrow: (direction: KeyboardEventCode) => void;
}

const linkToButton = (item: NavMenuItem): NavButtonMenuItem => {
  // eslint-disable-next-line no-prototype-builtins
  if (!item.hasOwnProperty('href')) {
    return item;
  }

  const { href: _href, ...rest } = item as NavLinkMenuItem;
  return rest;
};

const SidebarNavItem = forwardRef(
  (
    {
      item,
      isSelected,
      onSelectItemId,
      onNavigate,
      onKeyboardArrow,
    }: SidebarNavItemProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const hasContentOrChildMenu = !!item.content || !!item.childNavMenu?.length;
    const option = hasContentOrChildMenu ? linkToButton(item) : item;
    const optionClasses = cx(styles.menuItem, {
      [styles.menuItemSelected]: isSelected,
    });
    const wrapperClasses = cx({
      [item.className as string]: !!item.className,
    });
    const handleClick = (evt: React.UIEvent<HTMLElement>) => {
      // eslint-disable-next-line no-prototype-builtins
      if (option.hasOwnProperty('href')) {
        onNavigate(
          evt as React.UIEvent<HTMLAnchorElement>,
          item as NavLinkMenuItem
        );
      }
    };
    const handleSelectItemId = (id: string) => {
      // SidebarMegaNav only allows buttons to be selected
      // eslint-disable-next-line no-prototype-builtins
      if (!option.hasOwnProperty('href')) {
        onSelectItemId?.(id);
      }
    };

    return (
      <NavMenuOption
        key={item.id}
        ref={ref}
        wrapperClassName={wrapperClasses}
        className={optionClasses}
        item={option}
        isSelected={isSelected}
        onSelectItemId={handleSelectItemId}
        onClick={handleClick}
        onKeyboardArrow={onKeyboardArrow}
        disableSelectOnHover
      />
    );
  }
);

SidebarNavItem.displayName = 'SidebarNavItem';

export default SidebarNavItem;
