import React from 'react';
import { useTranslations } from '@unisporkal/localization';
import {
  ButtonVariantEnum,
  ButtonSizeEnum,
  Button,
} from '@unisporkal/alliance-ui-button';

import { useNavLinks } from '../../../../../hooks/getty/useNavLinks';
import AIGeneratorBadge from '../../icons/AIGeneratorBadge.svg?icon';
import styles from './StartGeneratingButton.module.scss';

const StartGeneratingButton = ({ onButtonClick }) => {
  const t = useTranslations();
  const { aiGeneratorGenerate } = useNavLinks();

  return (
    <Button
      className={styles.ctaButton}
      variant={ButtonVariantEnum.HOLLOW}
      size={ButtonSizeEnum.MEDIUM}
      href={aiGeneratorGenerate.href}
      onClick={(event) => onButtonClick(event, aiGeneratorGenerate)}
    >
      {t('start_generating')}
      <AIGeneratorBadge />
    </Button>
  );
};

export default StartGeneratingButton;
