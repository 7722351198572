import React from 'react';
import NavLinkOrButton, {
  NavLinkOrButtonCommonProps,
  NavLinkVariantEnum,
} from '../NavLinkOrButton/NavLinkOrButton';
import { NavLinkItem } from '../../../../../types/link';

interface NavLinkProps extends NavLinkOrButtonCommonProps {
  link: NavLinkItem;
}

const NavLink = ({
  link,
  icon = null,
  hideText = false,
  variant = NavLinkVariantEnum.OLD,
  onClick = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onExpandKeyboardToggle = null,
  isExpanded = false,
  className = '',
}: NavLinkProps) => (
  <NavLinkOrButton
    linkOrButtonText={link}
    icon={icon}
    hideText={hideText}
    variant={variant}
    onClick={onClick}
    onExpandKeyboardToggle={onExpandKeyboardToggle}
    isExpanded={isExpanded}
    className={className}
  />
);

export default NavLink;
export { NavLinkVariantEnum };
