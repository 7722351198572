import React from 'react';
import { Link } from '@unisporkal/alliance-ui-link';
import { LinkItem } from '../../../../../../types/link';
import ArrowEncircled from '../../icons/ArrowEncircled.svg?icon';
import styles from './MegaNavOverview.module.scss';

interface MegaNavOverviewProps {
  title: string;
  body: string;
  overviewLink: LinkItem;
  topSearchesLink?: LinkItem;
  secondaryLinks?: LinkItem[];
  onOverviewLinkClick: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem
  ) => void;
  onTopSearchesLinkClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem
  ) => void;
  onSecondaryLinkClick?: (
    evt: React.UIEvent<HTMLAnchorElement>,
    link: LinkItem
  ) => void;
  className?: string;
}

const MegaNavOverview = ({
  title,
  body,
  overviewLink,
  topSearchesLink,
  secondaryLinks,
  onOverviewLinkClick,
  onTopSearchesLinkClick,
  onSecondaryLinkClick,
  className = '',
}: MegaNavOverviewProps) => (
  <div
    data-testid="mega-nav-overview"
    className={`${styles.container} ${className}`}
  >
    <h6 className={styles.title}>{title}</h6>
    <p className={styles.text}>{body}</p>
    <Link
      data-testid="mega-nav-overview-link"
      href={overviewLink.href}
      className={styles.link}
      onClick={(evt) => onOverviewLinkClick(evt, overviewLink)}
    >
      {overviewLink.name}
      <ArrowEncircled className={styles.linkIcon} />
    </Link>
    {topSearchesLink && onTopSearchesLinkClick && (
      <Link
        data-testid="mega-nav-top-searches-link"
        href={topSearchesLink.href}
        className={styles.link}
        onClick={(evt) => onTopSearchesLinkClick(evt, topSearchesLink)}
      >
        {topSearchesLink.name}
      </Link>
    )}
    {secondaryLinks &&
      onSecondaryLinkClick &&
      secondaryLinks.map((secondaryLink) => (
        <Link
          key={secondaryLink.name}
          data-testid="mega-nav-secondary-link"
          href={secondaryLink.href}
          className={styles.link}
          onClick={(evt) => onSecondaryLinkClick(evt, secondaryLink)}
        >
          {secondaryLink.name}
        </Link>
      ))}
  </div>
);

export default MegaNavOverview;
