import React, { useState, useEffect, useMemo } from 'react';
import { useTranslations } from '@unisporkal/localization';
import useCreativeImages from '../../../../../hooks/getty/megaNav/useCreativeImages';
import useCreativeVideos from '../../../../../hooks/getty/megaNav/useCreativeVideos';
import useCreativeIllustrations from '../../../../../hooks/getty/megaNav/useCreativeIllustrations';
import useCreativeVectors from '../../../../../hooks/getty/megaNav/useCreativeVectors';
import useCreativeMusic from '../../../../../hooks/getty/megaNav/useCreativeMusic';
import { useNavLinks } from '../../../../../hooks/getty/useNavLinks';
import { NavMenuItem } from '../../../../../../types/link';
import { PILLTYPES } from '../../../../../constants';
import MegaNav from '../../MegaNav/MegaNav';
import megaNavTracking from '../../Tracking/Tracking';
import MegaNavContent from '../../MegaNavContent/MegaNavContent';

interface CreativeMegaNavProps {
  isOpen: boolean;
}

const CreativeMegaNav = ({ isOpen }: CreativeMegaNavProps) => {
  const t = useTranslations();
  const groupTitle = t('creative_content');
  const links = useNavLinks();
  const [selectedNavItemId, setSelectedNavItemId] = useState<string>(
    links.creativeImages.id
  );
  const [isViewAllCuratedPills, setIsViewAllCuratedPills] = useState(false);
  const [isViewAllPopularPills, setIsViewAllPopularPills] = useState(false);
  const [isViewAllTrendingPills, setIsViewAllTrendingPills] = useState(false);
  const image = useCreativeImages();
  const video = useCreativeVideos();
  const illustration = useCreativeIllustrations();
  const vector = useCreativeVectors();
  const music = useCreativeMusic();

  // Reset isViewAll state when selectedNavItemId changes
  useEffect(() => {
    setIsViewAllPopularPills(false);
    setIsViewAllCuratedPills(false);
    setIsViewAllTrendingPills(false);
  }, [selectedNavItemId]);

  const handleViewAllPillsClick =
    (trackingId: string) => (pillType: string) => {
      megaNavTracking.trackViewAllPillsNavigate(pillType, trackingId);

      switch (pillType) {
        case PILLTYPES.POPULAR:
          return setIsViewAllPopularPills(true);
        case PILLTYPES.TRENDING:
          return setIsViewAllTrendingPills(true);
        case PILLTYPES.CURATED:
          return setIsViewAllCuratedPills(true);
        default:
          return null;
      }
    };
  const trackPillClick = (trackingId) => (event, link, section) =>
    megaNavTracking.trackPillNavigate(event, link, section, trackingId);
  const trackOverviewLinkClick = (trackingId) => (event, link) =>
    megaNavTracking.trackOverviewNavigate(event, link, trackingId);

  const linkWithContent = (link, content) => ({
    ...link,
    content: (
      <MegaNavContent
        overviewLink={content.overviewLink}
        topSearchesLink={content.topSearchesLink}
        overviewText={content.overviewText}
        curatedPills={content.curatedPills}
        trendingPills={content.trendingPills}
        popularPills={content.popularPills}
        onPillClick={trackPillClick(content.trackingId)}
        onOverviewLinkClick={trackOverviewLinkClick(content.trackingId)}
        onTopSearchesLinkClick={trackOverviewLinkClick(content.trackingId)}
        isViewAllTrendingPills={isViewAllTrendingPills}
        isViewAllPopularPills={isViewAllPopularPills}
        onViewAllPillsClick={handleViewAllPillsClick(content.trackingId)}
      />
    ),
  });

  const navItems: NavMenuItem[] = useMemo(
    () => [
      linkWithContent(links.creativeImages, image),
      linkWithContent(links.creativeVideos, video),
      linkWithContent(links.creativeIllustrations, illustration),
      linkWithContent(links.creativeVectors, vector),
      linkWithContent(links.creativeMusic, music),
    ],
    [isViewAllPopularPills, isViewAllTrendingPills, isViewAllCuratedPills]
  );

  return (
    <MegaNav
      isOpen={isOpen}
      groupTitle={groupTitle}
      navItems={navItems}
      selectedNavItemId={selectedNavItemId}
      onSelectNavItemId={setSelectedNavItemId}
      onNavigate={(event, link) =>
        megaNavTracking.trackNavMenuNavigate(event, link, 'creative')
      }
    />
  );
};

export default CreativeMegaNav;
