import cx from 'classnames';
import React, { useState } from 'react';
import {
  Drawer,
  DrawerAnchorEnum,
  DrawerVariantEnum,
} from '@unisporkal/alliance-ui-drawer';
import {
  PANELTYPES,
  PANEL_VIEW,
  PANELTYPES_RIGHT,
} from '../../../../constants';
import { closePanelTrackingEvent } from '../../../../tracking/navigationMenuTracking';
import MainNavLeft from '../MainNavLeft/MainNavLeft';
import MainNavRight from '../MainNavRight/MainNavRight';
import MainNavTop from '../MainNavTop/MainNavTop';
import MobileMegaNav from '../MegaNavs/MobileMegaNav/MobileMegaNav';
import NavBar, { NavBarVariantEnum } from '../NavBar/NavBar';
import OverlayProvider from '../Overlay/OverlayProvider';
import Overlay from '../Overlay/Overlay';
import RightPanel from '../RightPanel/RightPanel';
import styles from './FullHeader.module.scss';

interface FullHeaderProps {
  showEnterpriseDropdown: boolean;
}

const panelSlideMs = 300;

const FullHeader = ({ showEnterpriseDropdown }: FullHeaderProps) => {
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false);
  const [isLeftPanelOpen, setIsLeftPanelOpen] = useState(false);
  const [rightPanelType, setRightPanelType] =
    useState<Nullable<PANELTYPES_RIGHT>>(null);

  const closeRightPanel = (panelType: PANEL_VIEW) => {
    setRightPanelType(null);
    // Note: open panel tracking is handled in the panel components
    closePanelTrackingEvent('right', panelType);
  };

  const closeLeftPanel = () => {
    setIsLeftPanelOpen(false);
    // Note: open panel tracking is handled in the panel components
    closePanelTrackingEvent('left', PANELTYPES.BROWSE);
  };

  return (
    <OverlayProvider
      isOpen={isOverlayOpen}
      setIsOpen={setIsOverlayOpen}
    >
      <Overlay />
      <header
        className={styles.container}
        data-testid="FullHeader"
        id="site-top-header-wrapper"
      >
        <MainNavTop
          isVisualGps={false}
          isLeftPanelOpen={isLeftPanelOpen}
          onOpenLeftPanel={() => setIsLeftPanelOpen(true)}
          onOpenRightPanel={setRightPanelType}
        />
        <NavBar
          variant={NavBarVariantEnum.SECONDARY}
          data-testid="nav-secondary"
        >
          <MainNavLeft />
          <MainNavRight
            showEnterpriseDropdown={showEnterpriseDropdown}
            isVisualGps={false}
          />
        </NavBar>

        <RightPanel
          className={styles.rightPanel}
          panelSlideMs={panelSlideMs}
          panelComponentType={rightPanelType}
          onPanelNavigate={setRightPanelType}
          onClose={closeRightPanel}
        />
        <Drawer
          anchor={DrawerAnchorEnum.LEFT}
          open={isLeftPanelOpen}
          onClose={closeLeftPanel}
          variant={DrawerVariantEnum.PRIMARY}
          className={cx(styles.leftPanel)}
          rootProps={{
            className: styles.leftPanelRoot,
          }}
          transitionDuration={panelSlideMs}
        >
          <MobileMegaNav />
        </Drawer>
      </header>
    </OverlayProvider>
  );
};

export default FullHeader;
