import { useTranslations } from '@unisporkal/localization';
import { useEditorialOverviewLinks } from '../useOverviewLinks';
import usePopularCategories from '../usePopularCategories';
import useHeaderPropsQuery from '../../queries/useHeaderPropsQuery';

export const useEditorialFashion = () => {
  const trackingId = 'Editorial_Fashion';
  const t = useTranslations();
  const popularCategories = usePopularCategories();
  const editorialPopularCategoryLinks =
    popularCategories.editorialPopularCategoryLinks();
  const editorialOverviewLinks = useEditorialOverviewLinks();
  const {
    data: { editorialTrendingSearchLinksFashion },
  } = useHeaderPropsQuery();

  return {
    trackingId,
    overviewLink: editorialOverviewLinks.editorialFashion,
    overviewText: {
      title: t('fashion'),
      body: t('browse_latest_images_videos_focused_on_fashion'),
    },
    trendingPills: {
      title: t('trending_fashion_searches'),
      items: editorialTrendingSearchLinksFashion,
    },
    popularPills: {
      title: t('popular_fashion_categories'),
      items: editorialPopularCategoryLinks.fashion,
    },
  };
};

export default useEditorialFashion;
